import { useState } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function InputPopup({isOpen, setIsOpen, callback, actionLabel, secondaryActionLabel, secondaryCallback, titleLabel})
{
	const [text, setText] = useState("");
	const [openDialog, setOpendialog] = useState(false);


	function onChange(event)
	{
	    event.preventDefault();
	    setText(event.target.value)
	}

	function onPositiveClick(e)
	{
		e.preventDefault();
		secondaryCallback()
		setOpendialog(false)
		setIsOpen(false)
	}

	function onNegativeClick(e)
	{
		e.preventDefault();
		setOpendialog(false)
	}
	
	return <>{isOpen?<div style={{marginBottom:"24px"}}>

		<Dialog
	        open={openDialog}
	        onClose={onNegativeClick}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	      >
	        <DialogTitle id="alert-dialog-title">
	          {secondaryActionLabel}
	        </DialogTitle>
	        <DialogContent>
	          <DialogContentText id="alert-dialog-description">
	            This operation can't be reversed.
	          </DialogContentText>
	        </DialogContent>
	        <DialogActions>
	          <Button onClick={onNegativeClick}>No</Button>
	          <Button onClick={onPositiveClick}>{secondaryActionLabel}</Button>
	        </DialogActions>
	      </Dialog>
		<p>{titleLabel}</p>
  		<TextField type="text" id="popup" name="popup" onChange={onChange} />
  		<Button variant="contained" onClick={()=>{ callback(text); setIsOpen(false) } } style={{marginLeft:"10px", height:"56px"}} >{actionLabel}</Button>
  		{secondaryActionLabel?<Button onClick={()=>{ setOpendialog(true) } } style={{marginLeft:"10px", height:"56px"}} >{secondaryActionLabel}</Button>:null}
  		</div>:null}
  	</>
}
