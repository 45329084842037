import React from 'react';
import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { isFeatureAvailable } from "../custom/config"
import { isUserLoggedIn } from "../services/user"

import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TheatersIcon from '@mui/icons-material/Theaters';
import WebIcon from '@mui/icons-material/Web';
import CategoryIcon from '@mui/icons-material/Category';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import TagIcon from '@mui/icons-material/Tag';
import PeopleIcon from '@mui/icons-material/People';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import VideocamIcon from '@mui/icons-material/Videocam';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BadgeIcon from '@mui/icons-material/Badge';

// icons
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';

export const sideMenuWidth = 240;

export function SideMenu({callback}) {

  let _menuItemsCore = [
    {name:'Dashboard', path:"dashboard", icon:"DashboardIcon"}
  ]

  let _menuItemsMetadata = []

  let _menuItemsManage = []

  let _menuItemsIntegration = []


  if (isFeatureAvailable("isVodAvailable"))
  {
    _menuItemsCore.push({name:'Movies',path:"vods", icon:"TheatersIcon"})
  }
  if (isFeatureAvailable("isLiveEventAvailable"))
  {
    _menuItemsCore.push({name:'Live Events',path:"events", icon:"VideocamIcon"})
  }

  if (isFeatureAvailable("isTvChannelAvailable"))
  {
    _menuItemsCore.push({name:'TV Channels',path:"tvchannels", icon:"LiveTvIcon"})
  }

  if (isFeatureAvailable("isVodAvailable") || isFeatureAvailable("isLiveEventAvailable"))
  {
    _menuItemsCore.push({name:'Pages and lists',path:"pages", icon:"WebIcon"})
    _menuItemsMetadata.push({name:'Categories', path:"metadata/categories", icon:"CategoryIcon", refresh:true})
    _menuItemsMetadata.push({name:'Tags', path:"metadata/tags", icon:"TagIcon", refresh:true})
    _menuItemsMetadata.push({name:'People', path:"metadata/people", icon:"PeopleIcon"})
  }

  _menuItemsManage.push({name:'Products', path:"finance/products", icon:"ShoppingBasketIcon"})
  _menuItemsManage.push({name:'Sales', path:"finance/sales", icon:"MonetizationOnIcon"})
  _menuItemsManage.push({name:'End users', path:"users", icon:"BadgeIcon"})

  _menuItemsIntegration.push({name:'Cloud Video Kit', path:"settings/cloudvideokit", icon:"CloudSyncIcon"})


  const [menuItemsCore, setMenuItemsCore] = useState(_menuItemsCore)
  const [menuItemsMetadata, setMenuItemsMetadata] = useState(_menuItemsMetadata)
  const [menuItemsManage, setMenuItemsManage] = useState(_menuItemsManage)
  const [menuItemsIntegration, setMenuItemsIntegration] = useState(_menuItemsIntegration)

  const [icon, setIcon] = useState("")
  const navigate = useNavigate();

  function goTo(codename, refresh)
  {
    navigate("/"+codename)
    if (callback)
    {
      callback(codename, refresh)
    }
  }

  function onBackClicked()
  {
    navigate(-1)
  }

  useEffect(() => 
  {

  }, [])

  return (
    <Drawer
        sx={{
          width: sideMenuWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: sideMenuWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
     
      <Toolbar />
      <Divider />
      <List>
        {menuItemsCore.map((item, index) => (
          <ListItem onClick={()=>goTo(item.path, item.refresh)} key={item.name} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {item.icon==="DashboardIcon"?<DashboardIcon/>:null}
                {item.icon==="TheatersIcon"?<TheatersIcon/>:null}
                {item.icon==="WebIcon"?<WebIcon/>:null}         
                {item.icon==="LiveTvIcon"?<LiveTvIcon/>:null}
                {item.icon==="VideocamIcon"?<VideocamIcon/>:null}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {menuItemsMetadata.map((item, index) => (
          <ListItem onClick={()=>goTo(item.path, item.refresh)} key={item.name} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {item.icon==="CategoryIcon"?<CategoryIcon/>:null}
                {item.icon==="TagIcon"?<TagIcon/>:null}
                {item.icon==="PeopleIcon"?<PeopleIcon/>:null}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {menuItemsManage.map((item, index) => (
          <ListItem onClick={()=>goTo(item.path, item.refresh)} key={item.name} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {item.icon==="ShoppingBasketIcon"?<ShoppingBasketIcon/>:null}
                {item.icon==="MonetizationOnIcon"?<MonetizationOnIcon/>:null}
                {item.icon==="BadgeIcon"?<BadgeIcon/>:null}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {menuItemsIntegration.map((item, index) => (
          <ListItem onClick={()=>goTo(item.path, item.refresh)} key={item.name} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {item.icon==="CloudSyncIcon"?<CloudSyncIcon/>:null}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}