import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getConfigValue } from "../custom/config"
import Stack from '@mui/material/Stack';

export function InputPopupPerson({isOpen, setIsOpen, callback})
{
	const [name, setName] = useState("");
	const [surename, setSurename] = useState("");
  	const [roleSelectedValue, setRoleSelectedValue] = useState("");
  	const [roleList, setRoleList] = useState("");



	function onChangeName(event)
	{
	    event.preventDefault();
	    setName(event.target.value)
	}

	function onChangeSurename(event)
	{
	    event.preventDefault();
	    setSurename(event.target.value)
	}

	const onRoleSelectChange = (event: SelectChangeEvent) => {
	    setRoleSelectedValue(event.target.value)
	};
	

	useEffect(() => 
	{
		setRoleList(getConfigValue("castRole"))
	}, [])

	return <>{isOpen?<div style={{marginBottom:"24px"}}>
		<p>Type in the name, surename and select role</p>
		<Stack direction="row" spacing={2}>
	  		<TextField type="text" id="fname" label="Name" name="fname" onChange={onChangeName} />
	  		<TextField type="text" id="surename" label="Surename" name="surename" onChange={onChangeSurename} />
	  		<FormControl>
		        <InputLabel  id="demo-simple-select-label">Role</InputLabel>
		        <Select
		          labelId="demo-simple-select-label"
		          id="demo-simple-select"
		          value={roleSelectedValue}
		          label="Role"
		          onChange={onRoleSelectChange}
		          style={{width:"128px"}}
		        >
		          {roleList.map((item) => (
		            <MenuItem value={item.codename}>{item.name}</MenuItem>
		          ))}
		        </Select>
		      </FormControl>
  			<Button variant="contained" onClick={()=>{ callback(name, surename, roleSelectedValue); setIsOpen(false) } } style={{marginLeft:"10px", height:"56px"}} >save</Button>
		</Stack>
  		</div>:null}
  	</>
}
