import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

export default function CustomButton({onClick, alertMessage, buttonTitle, showInfo, showLoader}) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ m: 0, position: 'relative' }}>
        <Button variant="contained" disabled={showLoader} onClick={onClick} size="large">{buttonTitle}</Button>
        {showLoader?<CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px'}} />:null}
        {showInfo?<Alert severity={"success"} sx={{ position: 'absolute', marginTop: '-42px', width:"100%"}}>{alertMessage}</Alert>:null}
      </Box>
    </Box>
  );
}