import Parse from 'parse';
import { useState, useRef, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu, sideMenuWidth } from "../components/sidemenu"
import { getProducts } from "../services/api"
import { getTimestampAsStringForDatePicker } from "../services/helper"

import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import { getConfigValue } from "../custom/config"

const rowStyle = {
  marginTop: "16px",
}

const rowTitle = {
  marginTop: "16px",
  marginBottom: "16px",
  fontWeight: "600",
  fontSize: "20px"
}

export default function TvChannel() 
{
  const { channel_id } = useParams();

  const defaultLogoSize = getConfigValue("logoSize")

  const navigate = useNavigate()  
  const [editUi, setEditUi] = useState(false);
  const [editProductsUi, setEditProductsUi] = useState(false);
  const [editMetadataUi, setEditMetadataUi] = useState(false);
  const [editImagesUi, setEditImagesUi] = useState(false);
  const [editMediafilesUi, setEditMediafilesUi] = useState(false);
  const [editAvailabilityUi, setEditAvailabilityUi] = useState(false);


  const [asset, setAsset] = useState({});

  const [objectId, setObjectId] = useState("");

  const [title, setTitle] = useState("");

  const [statusValue, setStatusValue] = useState("");
  const [statusName, setStatusName] = useState("");

  const [imagePosterUrl, setImagePosterUrl] = useState("");
  const [imagePosterSelected, setImagePosterSelected] = useState();

  const [mainMediaFileSearchPhrase, setMainMediaFileSearchPhrase] = useState("");
  const [mainMediaFileSelected, setMainMediaFileSelected] = useState();
  const [mainMediaFileName, setMainMediaFileName] = useState("");

  const [mainMediaFiles, setMainMediaFiles] = useState([]);

  const [products, setProducts] = useState([]);

  const [visibilityStartDateTimestamp, setVisibilityStartDateTimestamp] = useState(0);
  const [visibilityStartDateString, setVisibilityStartDateString] = useState("");

  const [visibilityStopDateTimestamp, setVisibilityStopDateTimestamp] = useState(0);
  const [stopVisibilityDateString, setStopVisibilityDateString] = useState("");
  
  async function getProductsData(assetProducts)
  {
    const result = await getProducts()
    if (assetProducts)
    {
      for (let i = 0; i < result.length; i++)
      {
        result[i].checked = false
        for (let j = 0; j < assetProducts.length; j++)
        {
          if (result[i].objectId === assetProducts[j])
          {
            result[i].checked = true
          }
        }
      }
    }
    setProducts(result)
  }

  async function getAssets(assetId)
  {
    const Asset = Parse.Object.extend("Channel");
    const query = new Parse.Query(Asset);
    query.include("mainMediaFile")
    const result = await query.get(assetId)
    setTitle(result.get("name"))

    if (result.get("imageLogoSmall"))
    {
      setImagePosterUrl(result.get("imageLogoSmall").url())
    }


    if (result.get("mainMediaFile"))
    {
      setMainMediaFileSelected(result.get("mainMediaFile").id)
      setMainMediaFileName(result.get("mainMediaFile").get("name"))
    }


    setStatusValue(result.get("status"))
    getProductsData(result.get("productObjectId"))

  }
  

  async function updateAssetData()
  {
    const Asset = Parse.Object.extend("Asset");
    const query = new Parse.Query(Asset);
    const asset = await query.get(objectId)

    asset.set("name", title)

    await asset.save()
    setViewAssetUi()
  }

  async function updateAssetProducts()
  {
    const Channel = Parse.Object.extend("Channel");
    const query = new Parse.Query(Channel);
    const channel = await query.get(objectId)
    
    let productObjectId = []
    for (let i = 0; i < products.length; i++)
    {
      if (products[i].checked === true)
      {
        productObjectId.push(products[i].objectId)
      }
    }
    channel.set("productObjectId", productObjectId)

    await channel.save()
    setViewAssetUi()
  }

  async function updateAssetImages()
  {
    const Channel = Parse.Object.extend("Channel");
    const query = new Parse.Query(Channel);
    const channel = await query.get(objectId)
    
    if (imagePosterSelected)
    {
        const imageNew = new Parse.File("poster.jpg", imagePosterSelected);
        const imageNewResult = await imageNew.save()
        channel.set("imagePosterSmall", imageNewResult)
    }

    await channel.save()
    setViewAssetUi()
  }

  async function updateAssetAvailability()
  {
    const Channel = Parse.Object.extend("Channel");
    const query = new Parse.Query(Channel);
    const channel = await query.get(objectId)
    channel.set("status", statusValue )

    await channel.save()
    setViewAssetUi()
  }


  function setEditAssetMetadataUi()
  {
    setEditUi(true)
    setEditMetadataUi(true)
    setEditImagesUi(false)
    setEditProductsUi(false)
    setEditMediafilesUi(false)
    setEditAvailabilityUi(false)
  }

  function setEditAssetProductsUi()
  {
    setEditUi(true)
    setEditMetadataUi(false)
    setEditImagesUi(false)
    setEditProductsUi(true)
    setEditMediafilesUi(false)
    setEditAvailabilityUi(false)
  }

  function setEditAssetImagesUi()
  {
    setEditUi(true)
    setEditMetadataUi(false)
    setEditImagesUi(true)
    setEditProductsUi(false)
    setEditMediafilesUi(false)    
    setEditAvailabilityUi(false)
  }

  function setEditAssetMediafileUi()
  {
    setEditUi(true)
    setEditMetadataUi(false)
    setEditImagesUi(false)
    setEditProductsUi(false)
    setEditMediafilesUi(true)
    setEditAvailabilityUi(false)
  }

  function setEditAssetAvailabilityUi()
  {
    setEditUi(true)
    setEditMetadataUi(false)
    setEditImagesUi(false)
    setEditProductsUi(false)
    setEditMediafilesUi(false)
    setEditAvailabilityUi(true)
  }

  function setViewAssetUi()
  {
    setEditUi(false)
  }

  // on input change callback
  function onTitleChange(event)
  {
    event.preventDefault();
    setTitle(event.target.value)
  }

  function onProductCheckboxChanged(event, objectId)
  {
    let _products = [...products]
    for (let i = 0; i < _products.length; i++)
      {
        if (_products[i].objectId === objectId)
        {
          _products[i].checked = !_products[i].checked
          break
        }
      }
    setProducts(_products)
  }

  // MEDIA FILES //
  async function findMediaFiles(phrase, setMediaFiles)
  {
    const MediaFile = Parse.Object.extend("MediaFile");
    const query = new Parse.Query(MediaFile);
    query.limit(5)
    query.contains("name", phrase);
    query.equalTo("whatType", "live")
    const result = await query.find()
    let items = []
    for (let i = 0; i < result.length; i++)
    {
      items.push({
        name: result[i].get("name"),
        objectId: result[i].id,
      })  
    }

    setMediaFiles(items)
  }

  async function updateAssetMediaFiles()
  {
    const Channel = Parse.Object.extend("Channel");
    const query = new Parse.Query(Channel);
    const channel = await query.get(objectId)

    if (mainMediaFileSelected)
    {
      const mediaFile = Parse.Object.extend("MediaFile");
      const MediaFilePointer = mediaFile.createWithoutData(mainMediaFileSelected);
      channel.set("mainMediaFile", MediaFilePointer)
    }

    await channel.save()
    setViewAssetUi()
  }

  function onMainMediaFileSearchChange(event)
  {
    event.preventDefault();
    setMainMediaFileSearchPhrase(event.target.value)
    if (event.target.value.length > 2)
    {
      findMediaFiles(event.target.value, setMainMediaFiles)
    }
  }


  function checkImageSize(imageUrl, definitionName, callback)
  {
    var img = new Image(); // check size of the image
    img.onload = function()
    {
      const size = getConfigValue(definitionName)
      if (size.width === this.width && size.height === this.height) // correct size!
      {
        callback(true, size)
      }
      else  // wront size
      {
        alert("zły rozmiar. Oczekiwany rozmiar to: szerokość: " + size.width+'px, wysokość: '+ size.height + "px" );
      }
    };
    img.src = imageUrl;
  }

  const imagePosterHandleChange = (event) => {
    const file = event.target.files[0];
    const objectURL = URL.createObjectURL(file)

    checkImageSize(objectURL, "posterSmallSize", (isSizeCorrect, correctSize)=>{
      if (isSizeCorrect)
      {
        setImagePosterSelected(file);
        setImagePosterUrl(objectURL)
      }
    })
  };

  const onSelectChange = (event: SelectChangeEvent) => {
    // setAge(event.target.value as string);
    setStatusValue(event.target.value)
  };


  useEffect(() => 
  {
    
      setObjectId(channel_id)
      setViewAssetUi()
      getAssets(channel_id)
    
  }, [])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <NavBar title={"Channel: " + title} showBackButton={true} />
        <Box
          component="nav"
          sx={{ width: { sm: sideMenuWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <SideMenu/>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${sideMenuWidth}px)` } }}
        >
          <Toolbar/>
          <Grid>     
                 <Grid container>
                    <Grid item>
                      <Button variant="text" onClick={setViewAssetUi}>overview</Button>
                    </Grid>
                    <Grid item>
                      <Button variant="text" onClick={setEditAssetMetadataUi}>edit metadata</Button>
                    </Grid>
                    <Grid item>
                      <Button variant="text" onClick={setEditAssetImagesUi}>edit images</Button>
                    </Grid>
                    <Grid item>
                      <Button variant="text" onClick={setEditAssetProductsUi}>edit products</Button>
                    </Grid>
                    <Grid item>
                      <Button variant="text" onClick={setEditAssetMediafileUi}>edit video files</Button>
                    </Grid>
                    <Grid item>
                      <Button variant="text" onClick={setEditAssetAvailabilityUi}>edit availability</Button>
                    </Grid>
                  </Grid>       
                 {editUi?<> {/* EDIT */}

                      {editMediafilesUi?
                      <Grid> 
                        <Grid container style={rowTitle}>
                          <Grid item>
                            <Button variant="contained" onClick={updateAssetMediaFiles}>save mediafile</Button>
                          </Grid>
                        </Grid>

                        <Grid container style={rowStyle}>
                          <Grid item xs={4}>
                            <TextField id="outlined-basic" label="search for main video file" variant="outlined" value={mainMediaFileSearchPhrase} onChange={onMainMediaFileSearchChange} />
                            <FormControl >
                              <InputLabel shrink htmlFor="select-multiple-native">
                                select main video file
                              </InputLabel>
                              {mainMediaFiles.length>0?<Select
                                multiple
                                native
                                label="Native"
                                inputProps={{
                                  id: 'main-media-file',
                                }}
                              >
                                {mainMediaFiles.map((item) => (
                                  <option key={item.objectId} value={item.name} onClick={()=>{setMainMediaFileSelected(item.objectId); setMainMediaFileName(item.name); setMainMediaFileSearchPhrase(""); setMainMediaFiles([]) }}>
                                    {item.name}
                                  </option>
                                ))}
                              </Select>:null}
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <b>Selected:</b> {mainMediaFileName}
                          </Grid>
                        </Grid>

                        
                      </Grid>
                      :null}

                      {editImagesUi?<Grid> 
                        <Grid container style={rowStyle}>
                          <Grid item>
                            <Button variant="contained" onClick={updateAssetImages}>save image</Button>
                          </Grid>
                        </Grid>

                        <Grid style={rowStyle}>
                         <Button variant="outlined" component="label">
                            set logo {defaultLogoSize.width}x{defaultLogoSize.height}px jpg
                            <input hidden accept=".jpg, .jpeg" type="file" onChange={imagePosterHandleChange} />
                          </Button>
    
                          {imagePosterUrl?<Grid item>
                             <img
                              src={imagePosterUrl}
                              width="128px"
                              height="128px"
                              alt="poster"
                              style={{width:"128px", height:"128px"}}
                            />
                          </Grid>:null}
                        </Grid>
                      </Grid>:null}

                      {editProductsUi?
                      <Grid> 
                        <Grid container style={rowTitle}>
                          <Grid item>
                            <Button variant="contained" onClick={updateAssetProducts}>save products</Button>
                          </Grid>
                        </Grid>

                        <Grid>
                            <div style={rowTitle}>Products list</div>
                            {products.map( (item) => (
                                <Grid item key={item.id}>
                                  <FormControlLabel control={<Checkbox checked={item.checked} onChange={(e)=>{onProductCheckboxChanged(e, item.objectId)} } />} label={item.name} />
                                </Grid>
                          
                            ) )}
                        </Grid>
                      </Grid>:null}

                      {editMetadataUi? 
                      <Grid> 
                        <Grid container style={rowTitle}>
                          <Grid item>
                            <Button variant="contained" onClick={updateAssetData}>save metadata</Button>
                          </Grid>
                        </Grid>
                        
                        <Grid style={rowStyle}>
                          <TextField style={{width:"640px"}} id="title" label="title" variant="outlined" value={title} onChange={onTitleChange} />
                        </Grid>
                                                
                      </Grid>:null}

                      {editAvailabilityUi? 
                      <Grid> 
                        <Grid container style={rowTitle}>
                          <Grid item>
                            <Button variant="contained" onClick={updateAssetAvailability}>Save availability settings</Button>
                          </Grid>
                        </Grid>
                        
                        <Grid container style={rowStyle}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={statusValue}
                              label="Status"
                              onChange={onSelectChange}
                            >
                              <MenuItem value={"new"}>New</MenuItem>
                              <MenuItem value={"unpublished"}>Unpublished</MenuItem>
                              <MenuItem value={"published"}>Published</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>:null}
                      
                  </>:<> {/* VIEW */}
                    
                      <h1>{title}</h1>
       
                      {imagePosterUrl?<Grid>
                        <Grid>
                          Logo
                        </Grid>
                        <Grid>
                           <img
                            src={imagePosterUrl}
                            width="128px"
                            height="128px"
                            alt="poster"
                            style={{width:"128px", height:"128px"}}
                          />
                        </Grid>
                      </Grid>:null}
                  </>}
          </Grid>
        </Box>
      </Box>
    </>
  );
}