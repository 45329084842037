import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu } from "../components/sidemenu"
import { Loader } from "../components/loader"
import { getConfigValue } from "../custom/config"

import Parse from "parse";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

export default function Authenticate() 
{
  const navigate = useNavigate()  
  const [isLoadingVod, setIsLoadingVod] = useState(false);
  const [showAlertVod, setShowAlertVod] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");


  const [isLoadingChannels, setIsLoadingChannels] = useState(false);

  
  async function syncVod()
  {
    setShowAlertVod(false)
    setAlertType("")
    setAlertMessage("")    
    setIsLoadingVod(true)
    const result = await Parse.Cloud.run("cvkSyncVod")
    setIsLoadingVod(false)
    
    if (result.status == 200)
    {
      setAlertType("success")
      setAlertMessage("sync completed")
      setShowAlertVod(true)
    }
    else if (result.status == 300)
    {
      setAlertType("info")
      setAlertMessage("all is up to date, no new data available.")
      setShowAlertVod(true)
    }
    else  
    {
      setAlertType("error")
      setAlertMessage("sync error, try again.")
      setShowAlertVod(true)
    }
  }

  async function syncChannels()
  {
    // setIsLoadingVod(true)
  }

  function openCvkAdmin()
  {
    window.open(getConfigValue("cvkAdminUrl"))
  }

  useEffect(() => 
  {
  
  }, [])

  return (
    <>
      <NavBar/>
      <Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <SideMenu/>
            </Grid>
            <Grid item>
              <Grid>
                <Grid style={{margin:"16px", marginTop: "100px"}}>
                  <Typography variant="h3">Cloud Video Kit</Typography>
                  <Button variant="outlined" onClick={openCvkAdmin} style={{marginBottom:"12px"}}>Open CVK admin</Button>
                  <Typography variant="subtitle1" style={{marginBottom:"32px"}}>Login and password to CVK admin are the same as to this Admin panel.</Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ m: 1, position: 'relative' }}>
                      <Button variant="contained" disabled={isLoadingVod} onClick={syncVod}>sync VOD</Button>
                      {isLoadingVod?<CircularProgress size={24} sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px'}} />:null}
                    </Box>
                    {showAlertVod?<Alert severity={alertType}>{alertMessage}</Alert>:null}
                  </Box>
                  <Typography variant="subtitle1">Start manual VOD synchronization</Typography>

                  {/*<Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ m: 1, position: 'relative' }}>
                      <Button variant="contained" disabled={isLoadingChannels} onClick={syncChannels}>sync Channels</Button>
                      {isLoadingChannels && (
                        <CircularProgress
                          size={24}
                          sx={{
                            // color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                        />
                      )}
                    </Box>
                  </Box>*/}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          </Grid>
      </Grid>
    </>
  );
}