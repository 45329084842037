import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { loginUser, isUserLoggedIn } from "../services/user"

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

export default function Authenticate() 
{
  const navigate = useNavigate()  
  const [loginUi, setLoginUi] = useState(true);
  const [resetPasswordUi, setResetPasswordUi] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isError, setIsError] = useState(false);




  async function onLoginCallback()
  {
    navigate("/dashboard")
  }

  // SUBMIT callback
  async function onLoginSubmit(event)
  {
    event.preventDefault();
    const loginResult = await loginUser(email, password)
    if (loginResult === "ok")
    {
      onLoginCallback()
    }
    else 
    {
      setIsError(true)
    }
  }


  function onResetPasswordSubmit(event)
  {
    event.preventDefault();
    // reset password API
    // on callbck show info

  }

  // on input change callback
  function onEmailChange(event)
  {
    event.preventDefault();
    setEmail(event.target.value)
  }

  function onPasswordChange(event)
  {
    event.preventDefault();
    setPassword(event.target.value)
    console.log(event.target.value)
  }

  // set UI status
  function showResetPasswordUi(event)
  {
    event.preventDefault();
    setResetPasswordUi(true)
    setLoginUi(false)
  }

  function showRegisterUi(event)
  {
    event.preventDefault();
    setResetPasswordUi(false)
    setLoginUi(false)
  }

  function showLoginUi(event)
  {
    event.preventDefault();
    setResetPasswordUi(false)
    setLoginUi(true)
  }

  useEffect(() => 
  {
    if (isUserLoggedIn())
    {
      navigate("/dashboard")
    }
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={onLoginSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              error={isError}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={onEmailChange}
              helperText={isError?"Incorrect entry.":""}
            />
            <TextField
              error={isError}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={onPasswordChange}
              helperText={isError?"Incorrect entry.":""}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                  Forgot password? Contact you account manager.
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
    </>
  );
}