import Parse from 'parse';
import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu, sideMenuWidth } from "../components/sidemenu"

import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Title',
    width: 250,
    editable: false,
  },
  {
    field: 'visibilityStartDate',
    headerName: 'Visibility start date',
    width: 150,
    editable: false,
  },
  {
    field: 'visibilityStopDate',
    headerName: 'Visibility stop date',
    width: 150,
    editable: false,
  },
  {
    field: 'mainMediaFileStartDate',
    headerName: 'Stream start date',
    width: 150,
    editable: false,
  },
  {
    field: 'mainMediaFileStopDate',
    headerName: 'Stream stop date',
    width: 150,
    editable: false,
  },
  {
    field: 'visibility',
    headerName: 'Visibility',
    width: 100,
    editable: false,
  },
  {
    field: 'stream',
    headerName: 'Stream',
    width: 100,
    editable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    editable: false,
  },
];



export default function AssetsList() 
{
  const navigate = useNavigate()  
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [searchSafeTimer, setSearchSafeTimer] = useState()
  
  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    navigate("/asset/"+params.row.objectId)
  };

  function addItem()
  {
    navigate("/asset/add_new_live")
  }

  async function getAssets(search_phrase)
  {
    setIsLoading(true)
    const Asset = Parse.Object.extend("Asset");
    const query = new Parse.Query(Asset);
    query.descending("createdAt")
    query.equalTo("whatType", "live")
    if (search_phrase && search_phrase !== "")
    {
      query.limit(5)
      query.contains("searchText", search_phrase.toLowerCase() )
    }
    else 
    {
      query.limit(100)
    }
    const result = await query.find()

    let items = []

    const current_time = Date.now() / 1000;
    for (let i = 0; i < result.length; i++)
    {
      let visibility_status = true
      let stream_status = "available"
      const status = result[i].get("status")
      if (current_time > result[i].get("visibilityStopDate")) // expired
      {
        visibility_status = false
      }
      if (current_time < result[i].get("visibilityStartDate")) // expired
      {
        visibility_status = false
      }

      if (result[i].get("mainMediaFile"))
      {
        if (current_time > result[i].get("mainMediaFileStopPublishDate")) // expired
        {
          stream_status = "expired"
        }
        else if (current_time < result[i].get("visibilityStartDate")) // expired
        {
          stream_status = "not visible yet"
        }
      }
      else  
      {
        stream_status = "no video file"
      }
       
      items.push({
        objectId: result[i].id,
        id: result[i].id,
        title: result[i].get("title"),
        status: result[i].get("status"),
        visibility: visibility_status,
        stream: stream_status,
        visibilityStartDate:new Date( result[i].get("visibilityStartDate")*1000).toLocaleString(),
        visibilityStopDate:new Date( result[i].get("visibilityStopDate")*1000).toLocaleString(),
        mainMediaFileStartDate:new Date( result[i].get("mainMediaFileStartPublishDate")*1000).toLocaleString(),
        mainMediaFileStopDate:new Date( result[i].get("mainMediaFileStopPublishDate")*1000).toLocaleString(),

      })
    }
    setIsLoading(false)
    setItems(items)
  }

  function onSearchChange(event)
  {
    event.preventDefault();
    clearTimeout(searchSafeTimer);
    setItems([])
    setSearchPhrase(event.target.value)
    setSearchSafeTimer(setTimeout(function(){
      if (event.target.value.length > 2)
      {
        getAssets(event.target.value)
      }
      else if (event.target.value.length === 0)
      {
        getAssets()
      }
    }, 1000) )

    if (event.target.value.length > 2)
    {
      setIsLoading(true)
    }
    else 
    {
      setIsLoading(false)
    }
  }

  useEffect(() => 
  {
    getAssets()
  }, [])

  return (
    <>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar title={"Live Events"} />
      <Box
        component="nav"
        sx={{ width: { sm: sideMenuWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <SideMenu/>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${sideMenuWidth}px)` } }}
      >
        <Toolbar/>
        <Grid>
          <Button variant="text" onClick={addItem}>Add new</Button>
          <TextField style={{width:"240px", marginBottom:"16px", marginLeft:"16px"}} id="search" label="search" variant="outlined" value={searchPhrase} onChange={onSearchChange} />
          <Box sx={{ height: "640px" }}>
          <DataGrid
            rows={items}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            onRowClick={handleRowClick}
            loading={isLoading}
          />
          </Box>
        </Grid>
      </Box>
    </Box>
    </>
  );
}