import Parse from "parse";
import { logoutUser, isUserLoggedIn } from "../services/user"
import { getMenuConfig } from "../services/config"
import { isFeatureAvailable } from "../custom/config"
import { isSafari } from "../services/helper"


let cacheDuration = 60 * 5
export const setCacheDuration = async(value) =>
{
  cacheDuration = value
  console.log(cacheDuration)
}

function isCacheActive(name, duration)
{
  const _timestamp = Number(window.localStorage.getItem(name))
  const current_time = Date.now();
  if (_timestamp && (_timestamp + duration) > current_time/1000) // cache is still on
  {
    return true
  }
  else  
  {
    const current_time = Date.now();
    window.localStorage.setItem(name, current_time/1000) // lat that app was fully new loaded
    return false
  }
}

function clearCache(name)
{
  window.localStorage.setItem(name, 0)
}

export const clearAllCachedListPages = async(name) =>
{
  const mainMenu = await getMenuConfig()
  for (let i = 0; i < mainMenu.length; i ++)
  {
    window.localStorage.setItem("getListsPageTimestamp_" + mainMenu[i].codename, 0)
  }
}

async function handleError(error_code)
{
  if (error_code === 209)
  {
    logoutUser()
  }
}

export const getProducts = async(whatType, objectIds) => 
{
    try 
    {
      const Product = Parse.Object.extend("Product");
      const queryProduct = new Parse.Query(Product);
      if (whatType)
      {
        queryProduct.equalTo("whatType", whatType)
      }
      if (objectIds)
      {
          queryProduct.containedIn("objectId", objectIds);
      }
      const resultsProduct = await queryProduct.find()
      // console.log(resultsProduct)
      let items = []
      for (let i = 0; i < resultsProduct.length; i++)
      {
        items.push({
          name: resultsProduct[i].get("name"),
          description: resultsProduct[i].get("description"),
          objectId: resultsProduct[i].id,
          price: resultsProduct[i].get("price") / 100,
        })
      }

      return items
    } 
    catch (error) 
    {
      await handleError(error.code)
      return []
    }
};

export const getUserPurchasedProducts = async(refresh) => 
{
    try 
    {
      const items = window.localStorage.getItem("getUserPurchasedProducts");
      if (!refresh && isCacheActive("getUserPurchasedProductsTimestamp", cacheDuration) === true && items)
      {
        return JSON.parse(items);
      }
      else  
      {
        const user_products = await Parse.Cloud.run("getUserPurchasedProducts")
        window.localStorage.setItem("getUserPurchasedProducts", JSON.stringify(user_products));
        return user_products
      }
    } 
    catch (error) 
    {
      await handleError(error.code)
      return []
    }
};

export const getUserProfile = async() => 
{
    try 
    {
      const item = window.localStorage.getItem("getUserProfile");
      if (item)
      {
        return JSON.parse(item);
      }
      else  
      {
        console.log(isUserLoggedIn())
        if (isUserLoggedIn() === true)
        {
          const UserProfile = Parse.Object.extend("UserProfile");
          const query = new Parse.Query(UserProfile);
          query.include("recommendation")
          const items = await query.find()
          if (items.length > 0)
          {
            window.localStorage.setItem("getUserProfile", JSON.stringify(items[0]));
            return items[0]
          }
          else  
          {
            return false
          }
        }
        else 
        {
          return false
        }
      }
    } 
    catch (error) 
    {
      await handleError(error.code)
      return false
    }
};

export const setUserProfile = async(userProfileObjectId) => 
{
    try 
    {
        const UserProfile = Parse.Object.extend("UserProfile");
        const query = new Parse.Query(UserProfile);
        query.include("recommendation")
        const item = await query.get(userProfileObjectId)
        if (item)
        {
          window.localStorage.setItem("getUserProfile", JSON.stringify(item));
          return item
        }
        else  
        {
          return []
        }
    } 
    catch (error) 
    {
      await handleError(error.code)
      return []
    }
};

export const getUserTransactionStatus = async(transactionObjectId) => 
{
    try 
    {
      return await Parse.Cloud.run("getUserTransactionStatus", {objectId: transactionObjectId})
    } 
    catch (error) 
    {
      await handleError(error.code)
      return false
    }
};

export const buyProduct = async(productObjectId, assetObjectId, returnUrl, returnErrorUrl) => 
{
  try 
  {
    clearCache("getUserPurchasedProductsTimestamp") // clear cache so new transactions will be downloaded
    const result = await Parse.Cloud.run("buyProduct", {productObjectId: productObjectId, assetObjectId: assetObjectId, returnUrl: returnUrl, returnErrorUrl: returnErrorUrl})
    if (result.url)
    {
      window.localStorage.setItem("lastTransactionObjectId", result.transactionObjectId); // save this ID to check status on thank you page.
      window.open(result.url, '_self');
    }
    return result
  }
  catch (error)
  {

  }
};

export const getActiveUsersCount = async(refresh) => 
{
    try 
    {
      const user_count = window.localStorage.getItem("getActiveUsersCount");
      if (!refresh && isCacheActive("getActiveUsersCountTimestamp", 60*600) === true && user_count)
      {
        return Number(user_count);
      }
      else  
      {
        const _user_count = await Parse.Cloud.run("getActiveUsersCount")
        window.localStorage.setItem("getActiveUsersCount", Number(_user_count.count));
        return Number(_user_count.count)
      }
    } 
    catch (error) 
    {
      await handleError(error.code)
      return []
    }
};

export const getSales = async(offset, refresh) => 
{
    try 
    {
      const sales = window.localStorage.getItem("getSales");
      if (!refresh && isCacheActive("getSalesTimestamp", 60*600) === true && sales)
      {
        return JSON.parse(sales);
      }
      else  
      {
        if (!offset) { offset = 0 }
        let start_date = new Date();
        start_date.setDate(start_date.getDate()-offset)
        const stop_date = new Date();
        const queryDate = {
          start_month: start_date.getMonth()+1,
          start_day: start_date.getDate(),
          start_year: start_date.getFullYear(),
          end_month: stop_date.getMonth()+1,
          end_day: stop_date.getDate(),
          end_year: stop_date.getFullYear()
        }

        const query_start_date = new Date(queryDate.start_month.toString() + " " + queryDate.start_day.toString() + " " + queryDate.start_year.toString() + " 0:00 UTC");
        const query_end_date = new Date(queryDate.end_month.toString() + " " + queryDate.end_day.toString() + " " + queryDate.end_year.toString() + " 23:59:59 UTC");

        const Transaction = Parse.Object.extend("Transaction");
        const query = new Parse.Query(Transaction);
        query.greaterThan("createdAt", query_start_date)
        query.lessThan("createdAt", query_end_date)
        query.include("product")
        query.equalTo("paymentStatus", "paid")
        const result = await query.find()
        let data = {
          totalValue:0,
          items: []
        }
        for (let i = 0; i < result.length; i++)
        {
          data.items.push({
            objectId: result[i].id,
            id: result[i].id,
            name: result[i].get("product").get("name"),
            price: result[i].get("price")/100,
            currency: result[i].get("currency"),      
          })
          data.totalValue = data.totalValue + result[i].get("price")
        }

        window.localStorage.setItem("getSales", JSON.stringify(data));
        return data
      }
    } 
    catch (error) 
    {
      await handleError(error.code)
      return []
    }
};

export const getMostPopularAssets = async(limit, offset, refresh) => 
{
    try 
    {
      const sales = window.localStorage.getItem("getMostPopularAssets");
      if (!refresh && isCacheActive("getMostPopularAssetsTimestamp", 60*600) === true && sales)
      {
        return JSON.parse(sales);
      }
      else  
      {
        if (!offset) { offset = 0 }
        if (!limit) { limit = 5 }

        let start_date = new Date();
        start_date.setDate(start_date.getDate()-offset)
        let stop_date = new Date();
        stop_date.setDate(stop_date.getDate()-offset)
        const queryDate = {
          start_month: start_date.getMonth()+1,
          start_day: start_date.getDate(),
          start_year: start_date.getFullYear(),
          end_month: stop_date.getMonth()+1,
          end_day: stop_date.getDate(),
          end_year: stop_date.getFullYear()
        }

        const query_start_date = new Date(queryDate.start_month.toString() + " " + queryDate.start_day.toString() + " " + queryDate.start_year.toString() + " 0:00 UTC");
        const query_end_date = new Date(queryDate.end_month.toString() + " " + queryDate.end_day.toString() + " " + queryDate.end_year.toString() + " 23:59:59 UTC");

        const Transaction = Parse.Object.extend("ReportWatchSession");
        const query = new Parse.Query(Transaction);
        query.greaterThan("createdAt", query_start_date)
        query.lessThan("createdAt", query_end_date)
        query.include("asset")
        query.equalTo("whatType", "vod")
        query.descending("totalWatchSessions")
        query.limit(10)
        const result = await query.find()
        let data = {
          items: []
        }
        for (let i = 0; i < result.length; i++)
        {
          data.items.push({
            objectId: result[i].id,
            id: result[i].id,
            name: result[i].get("asset").get("title"),
            number: result[i].get("totalWatchSessions"),    
          })
        }

        window.localStorage.setItem("getMostPopularAssets", JSON.stringify(data));
        return data
      }
    } 
    catch (error) 
    {
      await handleError(error.code)
      return []
    }
};


export const isIpAllowed = async() => 
{
  if (isFeatureAvailable("useIpRestriction"))
  {
    try 
    {
      const result = await Parse.Cloud.run("isIpAllowed")
      return result.isAllowed
    } 
    catch (error) 
    {
      await handleError(error.code)
      return false
    }
  }
  else  
  {
    return true
  }
};


// ADMIN
export const addIpRange = async() => 
{
    try 
    {
      return await Parse.Cloud.run("addIpRange",{startIpRange: "91.211.255.200" ,stopIpRange: "91.211.255.255"})
    } 
    catch (error) 
    {
      await handleError(error.code)
      return false
    }
};
