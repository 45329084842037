import * as React from 'react';
import { useState, useEffect} from "react";
import { useNavigate, useParams  } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu } from "../components/sidemenu"
import SalesTable from "../components/salesTable"

import Parse from "parse";

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';

const transactionsColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Product name',
    width: 250,
    editable: false,
  },
  {
    field: 'date',
    headerName: 'Purchase date',
    width: 150,
    editable: false,
  },
  {
    field: 'expiration',
    headerName: 'Expiration',
    width: 200,
    editable: false,
  },
  {
    field: 'price',
    headerName: 'Price',
    width: 100,
    editable: false,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    width: 100,
    editable: false,
  },
];

const watchSessionsColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Asset name',
    width: 250,
    editable: false,
  },
  {
    field: 'date',
    headerName: 'Start date',
    width: 250,
    editable: false,
  },
];

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function User() 
{
  const { user_id } = useParams();

  const navigate = useNavigate()  
  const [isLoading, setIsLoading] = useState(false);

  const [userStatus, setUserStatus] = useState("");
  const [username, setUsername] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");

  const [activeTab, setActiveTab] = React.useState(0);
  const [transactions, setTransactions] = useState([]);
  const [watchSessions, setWatchSessions] = useState([]);

  const handleTransactionsRowClick: GridEventListener<'rowClick'> = (params) => {
    // navigate("/pages/lists/list/"+params.row.objectId)
  };

  async function getItem()
  {
    const User = Parse.Object.extend("User");
    const query = new Parse.Query(User);
    const result = await query.get(user_id)
    setUserStatus(result.get("status"))
    setUsername(result.get("username"))
    setRegistrationDate(result.get("createdAt").toLocaleDateString())
  }

  async function getTransactions()
  {
    setIsLoading(true)
    const Transaction = Parse.Object.extend("Transaction");
    const query = new Parse.Query(Transaction);
    query.include("product")
    query.equalTo("userObjectId", user_id)
    query.descending("createdAt")
    const results = await query.find()
    let _items = []
    for (let i = 0; i < results.length; i++)
    {
      let _expiration = ""
      if (Date.now() > results[i].get("expirationDate")*1000)
      {
        _expiration = "EXPIRED on " + new Date(results[i].get("expirationDate")*1000).toLocaleDateString()
      }
      else  
      {
        _expiration = new Date(results[i].get("expirationDate")*1000).toLocaleDateString()
      }
      _items.push({
        objectId: results[i].id,
        id: results[i].id,
        name: results[i].get("product").get("name"),
        price: results[i].get("price")/100,
        currency: results[i].get("currency"),  
        date: results[i].get("createdAt").toLocaleDateString(), 
        expiration: _expiration,   
      })
    }
    setIsLoading(false)
    setTransactions(_items)
  }

  async function getWatchSessions()
  {
    setIsLoading(true)
    const WatchSession = Parse.Object.extend("WatchSession");
    const query = new Parse.Query(WatchSession);
    query.include("asset.title")
    query.include("channel.name")
    query.equalTo("userObjectId", user_id)
    query.descending("createdAt")
    const results = await query.find()
    let _items = []
    console.log(results)
    for (let i = 0; i < results.length; i++)
    {
      let _name = ""
      if (results[i].get("asset"))
      {
        _name = results[i].get("asset").get("title")
      }
      else if (results[i].get("channel"))
      {
        _name = results[i].get("channel").get("name")
      }
      _items.push({
        objectId: results[i].id,
        id: results[i].id,
        name: _name,
        date: results[i].get("createdAt").toLocaleDateString() + " | " +  results[i].get("createdAt").toLocaleTimeString(), 
      })
    }
    setIsLoading(false)
    setWatchSessions(_items)
  }

  async function setUserIsBlocked()
  {
    const User = Parse.Object.extend("User");
    const query = new Parse.Query(User);
    const result = await query.get(user_id)
    await result.save()

    if (userStatus === "blocked")
    {
      result.set("status", "active")
      setUserStatus("active")
    }
    else  
    {
      result.set("status", "blocked")
      setUserStatus("blocked")
    }
    await result.save()
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    if (newValue === 1)
    {
      getTransactions()
    }
    else if (newValue === 2)
    {
      getWatchSessions()
    }
  };

  useEffect(() => 
  {
    getItem()
  }, [])

  return (
    <>
     <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <NavBar title={"User: " + username} showBackButton={true} />
        <SideMenu/>
      </Box>
      <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            marginLeft:"240px"
          }}
        >
        <Toolbar />

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Tabs value={activeTab} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Details" />
            <Tab label="Transactions" />
            <Tab label="Watch sessions" />
            <Tab label="Settings" />
          </Tabs>

          <TabPanel value={activeTab} index={0}>
            <p><b>username:</b> {username}</p>
            <p><b>status:</b> {userStatus}</p>
            <p><b>registration date:</b> {registrationDate}</p>

            
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Paper sx={{height: "640px"}}>
              <DataGrid
                rows={transactions}
                columns={transactionsColumns}
                pageSize={50}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                onRowClick={handleTransactionsRowClick}
                loading={isLoading}
              />
            </Paper>
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <Paper sx={{height: "640px"}}>
              <DataGrid
                rows={watchSessions}
                columns={watchSessionsColumns}
                pageSize={50}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                loading={isLoading}
              />
            </Paper>
          </TabPanel>
          <TabPanel value={activeTab} index={3}>
            <p><b>status: user is </b> {userStatus}</p>
            {userStatus==="active"?<Button variant="outlined" color="error" onClick={setUserIsBlocked}>Block User</Button>:null}
            {userStatus==="blocked"?<Button variant="outlined" color="success" onClick={setUserIsBlocked}>Un block User</Button>:null}

          </TabPanel>
          
        </Container>
      </Box>
    </>
  );
}