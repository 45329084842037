import Parse from 'parse';
import { useState, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu, sideMenuWidth } from "../components/sidemenu"
import { getMenuConfig } from "../services/config"

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import Button from '@mui/material/Button';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'List name',
    width: 550,
    editable: false,
  },
  {
    field: 'isActive',
    headerName: 'status',
    width: 250,
    editable: false,
  }
];


export default function Lists() 
{
  const { page_id } = useParams();

  const navigate = useNavigate()  
  const [items, setItems] = useState([]);
  const [pageName, setPageName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const [enableAddButton, setEnableAddButton] = useState(false);
  const [pageTitle, setPageTitle] = useState("")

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    navigate("/pages/lists/list/"+params.row.objectId)
  };

  async function getItems(pageCodename, pageWhatType)
  {
    setIsLoading(true)
    const List = Parse.Object.extend("List");
    const query = new Parse.Query(List);
    query.equalTo("pageCodenames", pageCodename)
    // query.include("recommendation")
    const result = await query.find()

    let items = []

    for (let i = 0; i < result.length; i++)
    {
      items.push({
        objectId: result[i].id,
        id: result[i].id,
        name: result[i].get("name"),
        isActive: result[i].get("isActive")?"Active":"Not active"
      })
    }
    setIsLoading(false)
    if (pageWhatType === "grid")
    {
      if (items.length === 1)
      {
        setShowAddButton(false) // allow only one list for GRID type page
      }
      else  
      {
        setEnableAddButton(true)
      }
    }
    else  
    {
      setEnableAddButton(true)
    }
    setItems(items)
  }

  function addItem()
  {
    navigate("/pages/lists/list/add_new")
  }

  async function getPageName()
  {
    let items = await getMenuConfig()
    let _pageWhatType = ""
    let _pageName = ""
    for (let i = 0; i < items.length; i++)
    {
      if (items[i].codename === page_id)
      {
        setPageName(items[i].name)
        _pageName = items[i].name
        _pageWhatType = items[i].whatType
        break
      }
    }
    getItems(page_id, _pageWhatType)
    setPageTitle("Lists for page: " + _pageName + " | " + _pageWhatType )
  }

  useEffect(() => 
  {
    getPageName()
    
  }, [])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar title={pageTitle} showBackButton={true} />
      <Box
        component="nav"
        sx={{ width: { sm: sideMenuWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <SideMenu/>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${sideMenuWidth}px)` } }}
      >
        <Toolbar/>
        <Grid>
          {showAddButton?<Button disabled={!enableAddButton} variant="text" onClick={addItem}>Add new</Button>:null}
          <Box sx={{ height: "640px" }}>
          <DataGrid
            rows={items}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            onRowClick={handleRowClick}
            loading={isLoading}
          />
          </Box>
        </Grid>
      </Box>
     </Box>
   
  );
}