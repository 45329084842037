import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../components/title';


function preventDefault(event) {
  event.preventDefault();
}

export default function SalesTable({items}) {
  return (
    <React.Fragment>
      <Title>Search result</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
              <TableCell>UserName</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.status}</TableCell>
              <Link href={"/users/"+row.id}>view</Link>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}