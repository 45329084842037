import React from 'react';
import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { isFeatureAvailable } from "../custom/config"
import { isUserLoggedIn, logoutUser } from "../services/user"

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const drawerWidth = 240;

export function NavBar({callback, title, showBackButton}) {
  const [menuItems, setMenuItems] = useState([])
  const [icon, setIcon] = useState("")
  const navigate = useNavigate();

  function goTo(path, codename)
  {
    navigate("/"+path+"/"+codename)
    if (callback)
    {
      callback(codename)
    }
  }

  function onBackClicked()
  {
    navigate(-1)
  }

  async function onLogoutClicked()
  {
    const result = await logoutUser()
    if (result)
    {
      navigate("/")
    }
  }

  async function setItems()
  {

    let _menu_items = []
 
    if (isFeatureAvailable("useAuthentication"))
    {
      if (isUserLoggedIn())
      {
        _menu_items.push({
          name:"moje konto",
          codename:"myaccount",
          whatType: "page"
        })
      } 
      else 
      { 
        _menu_items.push({
          name:"logowanie",
          codename:"authenticate",
          whatType: "page"
        })
      }
    }
    setMenuItems(_menu_items)
  }

  useEffect(() => 
  {
    setItems()
  }, [])

  return (
    <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
     <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {showBackButton?<IconButton edge="start" color="inherit" aria-label="back" sx={{ mr: 2 }} onClick={onBackClicked}>
            <ArrowBackIcon />
          </IconButton>:null}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title?title:"Cloud Video Apps admin"}
          </Typography>
          <Button color="inherit" onClick={onLogoutClicked}>Logout</Button>

        </Toolbar>
      </AppBar>
    </Box>
 
    </AppBar>
  );
}