import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu } from "../components/sidemenu"
import { getActiveUsersCount, getSales, getMostPopularAssets } from "../services/api"
import { getConfigValue, getSystemValue, getReportsValue } from "../custom/config"
import InfoBox from "../components/infoBox"
import InfoTable from "../components/infoTable"

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export default function Authenticate() 
{
  const navigate = useNavigate()  
  const [activeUsersCount, setActiveUsersCount] = useState(0);
  const [totalSalesValue, setTotalSalesValue] = useState(0);
  const [mostPopularAssets, setMostPopularAssets] = useState([]);



  async function getData()
  {
    const activeUsersResult = await getActiveUsersCount()
    setActiveUsersCount(activeUsersResult)

    const salesResult = await getSales(getReportsValue("dashboardSalesOffset"))
    setTotalSalesValue(salesResult.totalValue/100)

    const mostPopularAssetsResult = await getMostPopularAssets(getReportsValue("dashboardMostPopularAssetsLimit"))
    setMostPopularAssets(mostPopularAssetsResult.items)
  }

  useEffect(() => 
  { 
    getData()
  }, [])

  return (
    <>
      <NavBar/>
      <Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <SideMenu/>
            </Grid>
            <Grid item>
              <Grid container spacing={{ xs: 2 }} columns={{ xs: 12 }} style={{margin:"16px", marginTop: "100px"}}>
                <Grid item xs={6}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 140,
                    }}
                    >
                    <InfoBox title={"Active users"} totalValue={activeUsersCount} dateString={"active user is: user who has at leas one active product or watched video content this month."} />
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 140,
                    }}
                    >
                    <InfoBox title={"Sales"} totalValue={totalSalesValue + " " + getConfigValue("currency")} dateString={"last " + getReportsValue("dashboardSalesOffset") + " days"} />
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={{ xs: 2 }} columns={{ xs: 12 }} style={{margin:"16px"}}>
                <Grid item xs={6}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                    >
                    <InfoTable title={"Popular assets (yesterday)"} columns={[{name:"name", key:1},{name:"views",key:2}]} items={mostPopularAssets} />

                  </Paper>
                </Grid>
                {/*<Grid item xs={6}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                    >
                    <InfoTable title={"Popular channels"} columns={[{name:"name"},{name:"views"}]} items={[{name:"TVN", number:12},{name:"Polsat", number:12}]} />
                    
                  </Paper>
                </Grid>*/}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}