import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu } from "../components/sidemenu"
import InfoBox from "../components/infoBox"
import SalesTable from "../components/salesTable"
import { getConfigValue } from "../custom/config"
import { getSales } from "../services/api"

import Parse from "parse";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';

export default function Authenticate() 
{
  const navigate = useNavigate()  
  const [items, setItems] = useState([]);
  const [todayTotalValue, setTodayTotalValue] = useState(0);
  const [todayDateString, setTodayDateString] = useState("");


  
  async function getItems()
  {
    let date = new Date();

    const result = await getSales(0)
    let _items = []
    let _todayTotalValue = 0
    for (let i = 0; i < result.length; i++)
    {
      _items.push({
        objectId: result[i].id,
        id: result[i].id,
        name: result[i].get("product").get("name"),
        price: result[i].get("price")/100,
        currency: result[i].get("currency"),      
      })
      _todayTotalValue = _todayTotalValue + result[i].get("price")
    }

    setTodayDateString(date.toLocaleDateString())
    setTodayTotalValue(_todayTotalValue/100)
    setItems(_items)
  }
  useEffect(() => 
  {
    getItems()
  }, [])

  return (
    <>
     <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <NavBar/>
        <SideMenu/>
      </Box>
      <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            marginLeft:"240px"
          }}
        >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Button variant="text" onClick={()=>{ window.open(getConfigValue("paymentGetawayAdminUrl"), '_blank'); }}>open Full financial report</Button>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <SalesTable items={items} title={"Today's 20 recent payments"}/>
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 240,
                }}
              >
                <InfoBox title={"Today income"} totalValue={todayTotalValue + " " + getConfigValue("currency")} dateString={todayDateString} />
              </Paper>
            </Grid>
            {/* Recent Orders */}
           {/* <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                
              </Paper>
            </Grid>*/}
          </Grid>
        </Container>
      </Box>
    </>
  );
}