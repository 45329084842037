import Parse from 'parse';
import { useState, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu, sideMenuWidth } from "../components/sidemenu"
import { InputPopup } from "../components/inputPopup"

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import Button from '@mui/material/Button';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Title',
    width: 250,
    editable: false,
  }
];

async function getItems(setItems, setIsLoading, whatType)
{
  setIsLoading(true)
  const Category = Parse.Object.extend("Category");
  const query = new Parse.Query(Category);
  query.ascending("name")
  query.equalTo("whatType", whatType)  
  const result = await query.find()

  let items = []

  for (let i = 0; i < result.length; i++)
  {
    items.push({
      id: result[i].id,
      objectId: result[i].id,
      name: result[i].get("name"),
    })
  }
  setIsLoading(false)
  setItems(items)
}



export default function Categories() 
{
  const navigate = useNavigate()  
  const { page_id } = useParams();

  const [pageTitle, setPageTitle] = useState("");

  const [items, setItems] = useState([]);
  const [isInputPopupOpen, setIsInputPopupOpen] = useState(false);
  const [isUpdateInputPopupOpen, setIsUpdateInputPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [whatType, setWhatType] = useState("");
  const [selectedCategoryObjectId, setSelectedCategoryObjectId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");


  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    setSelectedCategoryObjectId(params.row.objectId)
    setSelectedCategoryName(params.row.name)
    setIsUpdateInputPopupOpen(true)
  };


  async function addCategory(name)
  {
    const Add = Parse.Object.extend("Category");
    let add = new Add;
    let acl = new Parse.ACL()
    acl.setWriteAccess("*", false)
    acl.setReadAccess("*", true)
    acl.setRoleReadAccess("admin", true)
    acl.setRoleWriteAccess("admin", true)
    add.setACL(acl);
    add.set("name", name.toLowerCase())
    add.set("whatType", whatType)
    const result =  await add.save();
    if (result.id)
    {
      const _items = [...items]
      _items.push({
        id: result.id,
        objectId: result.id,
        name: name,
      })
      setItems(_items)
    }
  }

  async function updateCategory(name)
  {
    const Category = Parse.Object.extend("Category");
    const query = new Parse.Query(Category);
    const result = await query.get(selectedCategoryObjectId)
    result.set("name", name)
    await result.save()
    getItems(setItems, setIsLoading, whatType)
  }

  async function deleteCategory()
  {
    const Category = Parse.Object.extend("Category");
    const query = new Parse.Query(Category);
    const result = await query.get(selectedCategoryObjectId)
    await result.destroy()
    getItems(setItems, setIsLoading, whatType)
  }


  function addItem()
  {
    setIsInputPopupOpen(true)
  }

  function refreshPage(pagePath, refresh)
  {
    if (refresh)
    {
      window.location.reload(false);
    }
  }

  useEffect(() => 
  {
    let _whatType = ""
    if (page_id === "categories")
    {
      _whatType = "category"
      setPageTitle("Categories")
    }
    else if (page_id === "tags")
    {
      _whatType = "tag"
      setPageTitle("Tags")
    }
    setWhatType(_whatType)
    getItems(setItems, setIsLoading, _whatType)
  }, [])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar title={pageTitle}/>
      <Box
        component="nav"
        sx={{ width: { sm: sideMenuWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <SideMenu callback={refreshPage}/>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${sideMenuWidth}px)` } }}
      >
        <Toolbar/>
        <Grid>
          <Grid>
            <Button variant="text" onClick={addItem}>Add new</Button>
            <InputPopup isOpen={isInputPopupOpen} setIsOpen={setIsInputPopupOpen} callback={addCategory} actionLabel={"add"} titleLabel={"Type in full category name"}/>
            <InputPopup isOpen={isUpdateInputPopupOpen} setIsOpen={setIsUpdateInputPopupOpen} callback={updateCategory} actionLabel={"update"} secondaryActionLabel={"delete"} secondaryCallback={deleteCategory} titleLabel={"Type in full new "+whatType+" name. Current: '"+selectedCategoryName +"'"}/>
          </Grid>
          <Box sx={{ height: "640px" }}>
          <DataGrid
            rows={items}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            loading={isLoading}
            onRowClick={handleRowClick}
          />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}