import Parse from 'parse';
import { useState, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu, sideMenuWidth } from "../components/sidemenu"
import { InputPopupPerson } from "../components/inputPopupPerson"

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import Button from '@mui/material/Button';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    editable: false,
  },
  {
    field: 'surename',
    headerName: 'Surename',
    width: 250,
    editable: false,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 250,
    editable: false,
  }
];

async function getItems(setItems, setIsLoading)
{
  setIsLoading(true)
  const People = Parse.Object.extend("Person");
  const query = new Parse.Query(People);
  query.ascending("name")
  const result = await query.find()

  let items = []

  for (let i = 0; i < result.length; i++)
  {
    items.push({
      id: result[i].id,
      objectId: result[i].id,
      name: result[i].get("name"),
      surename: result[i].get("surename"),
      role: result[i].get("role"),
    })
  }
  setIsLoading(false)
  setItems(items)
}



export default function People() 
{
  const navigate = useNavigate()  
  const { page_id } = useParams();

  const [pageTitle, setPageTitle] = useState("");

  const [items, setItems] = useState([]);
  const [isInputPopupOpen, setIsInputPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  async function addNew(name, surename, role)
  {
    const Add = Parse.Object.extend("Person");
    let add = new Add;
    let acl = new Parse.ACL()
    acl.setWriteAccess("*", false)
    acl.setReadAccess("*", false)
    acl.setRoleReadAccess("admin", true)
    acl.setRoleWriteAccess("admin", true)
    add.setACL(acl);
    add.set("name", name)
    add.set("surename", surename)
    add.set("role", role)
    add.set("searchText", name.toLowerCase() + " " + surename.toLowerCase())

    const result =  await add.save();
    if (result.id)
    {
      const _items = [...items]
      _items.push({
        id: result.id,
        objectId: result.id,
        name: name,
        surename: surename,
        role: role        
      })
      setItems(_items)
    }
  }


  function addItem()
  {
    setIsInputPopupOpen(true)
  }

  function refreshPage(pagePath, refresh)
  {
    if (refresh)
    {
      console.log("refresh")
      window.location.reload(false);
    }
  }

  useEffect(() => 
  {
    getItems(setItems, setIsLoading)
  }, [])

  return (
    <>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar title={pageTitle}/>
      <Box
        component="nav"
        sx={{ width: { sm: sideMenuWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <SideMenu callback={refreshPage}/>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${sideMenuWidth}px)` } }}
      >
        <Toolbar/>
        <Grid>
          <Grid>
            <Button variant="text" onClick={addItem}>Add new</Button>
            <InputPopupPerson isOpen={isInputPopupOpen} setIsOpen={setIsInputPopupOpen} callback={addNew}/>
          </Grid>
          <Box sx={{ height: "640px" }}>
          <DataGrid
            rows={items}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            loading={isLoading}
          />
          </Box>
        </Grid>
      </Box>
    </Box>
  {/*    <NavBar/>
      
      <InputPopup isOpen={isInputPopupOpen} setIsOpen={setIsInputPopupOpen} callback={addCategory}/>
      <Container fluid>
        <Row>
          <Col xs="auto">
            <SideMenu/>
          </Col>
          <Col>
            <Row>
              <Col style={{margin:"16px"}}>
                <Button onClick={addItem}>Dodaj</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table hover>
                  <thead>
                    <tr>
                      <th>Nazwa</th>
                
                    </tr>
                  </thead>
                  <tbody>
                   {items.map((item) => ( 
                      <tr key={item.objectId}>
                        <td>{item.name}</td>
                    
                      </tr>
                    ) )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>*/}
    </>
  );
}