import Parse from 'parse';
import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu, sideMenuWidth } from "../components/sidemenu"
import { getMenuConfig } from "../services/config"

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import Button from '@mui/material/Button';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Page name',
    width: 250,
    editable: false,
  },
   {
    field: 'whatType',
    headerName: 'What type',
    width: 250,
    editable: false,
  },
   {
    field: 'visibility',
    headerName: 'Visibility',
    width: 250,
    editable: false,
  }
];

async function getPages(setItems, setIsLoading)
{  
  setIsLoading(true)
  const items = await getMenuConfig()
  setIsLoading(false)
  let _items = []
  for (let i = 0; i < items.length; i++)
  {
    if (items[i].whatType === "lists" || items[i].whatType === "grid")
    {
      _items.push(items[i])
    }
  }
  setItems(_items)
}

export default function Pages() 
{
  const navigate = useNavigate()  
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    if (params.row.whatType === "lists" || params.row.whatType === "grid")
    {
      navigate("/pages/lists/" + params.row.codename)
    }
    else if (params.row.whatType === "page")
    {
      navigate("/pages/page/" + params.row.codename)
    }
  };

  useEffect(() => 
  {
    getPages(setItems, setIsLoading)
  }, [])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar title={"Pages"} />
      <Box
        component="nav"
        sx={{ width: { sm: sideMenuWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <SideMenu/>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${sideMenuWidth}px)` } }}
      >
        <Toolbar/>
        <Grid>
          <Box sx={{ height: "640px" }}>
          <DataGrid
            rows={items}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            onRowClick={handleRowClick}
            loading={isLoading}
          />
          </Box>
        </Grid>
      </Box>
     </Box>
  );
}