import Parse from 'parse';
import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu, sideMenuWidth } from "../components/sidemenu"

import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Product name',
    width: 200,
    editable: false,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 400,
    editable: false,
  },
  {
    field: 'whatType',
    headerName: 'Type',
    width: 100,
    editable: false,
  },
  {
    field: 'price',
    headerName: 'Price',
    width: 100,
    editable: false,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    width: 100,
    editable: false,
  },
  {
    field: 'isActive',
    headerName: 'Status',
    width: 100,
    editable: false,
  }
];

async function getItems(setItems, setIsLoading)
{
  setIsLoading(true)
  const Product = Parse.Object.extend("Product");
  const query = new Parse.Query(Product);
  const result = await query.find()

  let items = []

  for (let i = 0; i < result.length; i++)
  {
    items.push({
      objectId: result[i].id,
      id: result[i].id,
      title: result[i].get("name"),
      description: result[i].get("description"),
      whatType: result[i].get("whatType"),
      price: result[i].get("price")/100,
      currency: result[i].get("currency"),      
      status: result[i].get("status"),
      isActive: result[i].get("isActive")?"Active":"Not active"

    })
  }
  setIsLoading(false)
  setItems(items)
}

export default function AssetsList() 
{
  const navigate = useNavigate()  
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function addItem()
  {
   navigate("/finance/products/add_new")
  }

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    navigate("/finance/products/"+params.row.objectId)
  };

  useEffect(() => 
  {
    getItems(setItems, setIsLoading)
  }, [])

  return (
    <>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar title={"Products"} />
      <Box
        component="nav"
        sx={{ width: { sm: sideMenuWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <SideMenu/>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${sideMenuWidth}px)` } }}
      >
        <Toolbar/>
        <Grid>
          <Button variant="text" onClick={addItem}>Add new</Button>
          <Box sx={{ height: "640px" }}>
            <DataGrid
              rows={items}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              onRowClick={handleRowClick}
              loading={isLoading}
            />
          </Box>
        </Grid>
      </Box>
    </Box>
    </>
  );
}