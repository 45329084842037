const features = {
  forceAuthenticationToWatch: true,
  useAuthentication: true,
  useUserProfiles: true,
  useUserRecommendations: true,
  useIpRestriction: true,
  forceDeviceRegistrationToWatch:true,
  mobileAppsAvailable:true,
  isTvChannelAvailable:true,
  isLiveEventAvailable:true,
  isVodAvailable:true,
}

const config = {
  posterSmallSize:{width:144,height:204},
  posterMediumSize:{width:480,height:680},
  bannerSmallSize:{width:256,height:144},
  bannerMediumSize:{width:480,height:270},
  bannerLargeSize:{width:1600,height:900},
  logoSize:{width:256,height:256},

  assetTypes:[{name:"vod", codename:"vod"},{name:"live",codename:"live"}],
  // listTypes:[{name:"search",codename:"search"},{name:"userRecommendation",codename:"userRecommendation"},{name:"userFavorite",codename:"userFavorite"},{name:"static",codename:"static"}],
  listTypes:[{name:"search",codename:"search"},{name:"static",codename:"static"}],

  tileTypes:[{name:"poster",codename:"poster"},{name:"image",codename:"image"},{name:"banner image",codename:"banner_image"},{name:"banner poster",codename:"banner_poster"}],
  productTypes:[{name:"TVOD",codename:"tvod"},{name:"SVOD",codename:"svod"}],
}

const custom = {
  ageRating:[{name:"none",codename:""},{name:"0",codename:"no_one"},{name:"7️",codename:"no_two"},{name:"12",codename:"no_three"},{name:"16",codename:"no_four"}],
  castRole:[{name:"Director",codename:"director"},{name:"Actor",codename:"actor"},{name:"Writer",codename:"writer"}],
  appName: "streaming app",
  paymentGetawayAdminUrl:"https://panel.tpay.com/",
  deviceLimit: 10,
  currency: "pln",
  cvkAdminUrl:"https://console.videokit.cloud/"
}

const system = {
  packageUserLimit: 1000
}

const reports = {
  dashboardSalesOffset: 7,
  dashboardMostPopularAssetsLimit: 10
}


export const isFeatureAvailable = (name) => 
{
  if (features[name] !== null && features[name] === true)
  {
    return true
  }
  else  
  {
    return false
  }
};

export const getConfigValue = (name) => 
{
  // console.log(config[name])
  if (config[name])
  {
    return config[name]
  }
  else if (custom[name])
  {
    return custom[name]
  }
  else  
  {
    return false
  }
};

export const getSystemValue = (name) => 
{
  // console.log(config[name])
  if (system[name])
  {
    return system[name]
  }
  else  
  {
    return false
  }
};

export const getReportsValue = (name) => 
{
  if (reports[name])
  {
    return reports[name]
  }
  else  
  {
    return false
  }
};
