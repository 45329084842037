import * as React from 'react';
import Parse from 'parse';

import { useState, useRef, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu, sideMenuWidth } from "../components/sidemenu"
import { getProducts } from "../services/api"
import { getTimestampAsStringForDatePicker } from "../services/helper"

import CustomButton from "../components/button"

import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { getConfigValue } from "../custom/config"

const rowStyle = {
  marginTop: "16px",
}

const rowTitle = {
  marginTop: "16px",
  marginBottom: "16px",
  fontWeight: "600",
  fontSize: "20px"
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function Asset() 
{
  const { asset_id } = useParams();
  
  const [activeTab, setActiveTab] = React.useState(0);

  const defaultImagesSizePosterSmall = getConfigValue("posterSmallSize")
  const defaultImagesSizePosterMedium = getConfigValue("posterMediumSize")
  const defaultImagesSizeBannerSmall = getConfigValue("bannerSmallSize")
  const defaultImagesSizeBannerMedium = getConfigValue("bannerMediumSize")
  const defaultImagesSizeBannerLarge = getConfigValue("bannerLargeSize")

  const navigate = useNavigate()  
  const [newAssetUi, setNewAssetUi] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showInfoLabelForSaveButton, setShowInfoLabelForSaveButton] = useState(false);

  const [asset, setAsset] = useState({});

  const [objectId, setObjectId] = useState("");

  const [whatType, setWhatType] = useState("vod");

  const [title, setTitle] = useState("");
  const [originalTitle, setOriginalTitle] = useState("");
  const [description, setDescription] = useState("");

  const [year, setYear] = useState(0);
  const [duration, setDuration] = useState(0);

  const [shortDescription, setShortDescription] = useState("");
  const [statusValue, setStatusValue] = useState("");

  const [imagePosterSmallUrl, setImagePosterSmallUrl] = useState("");
  const [imagePosterSmallSelected, setImagePosterSmallSelected] = useState();

  const [imagePosterMediumUrl, setImagePosterMediumUrl] = useState("");
  const [imagePosterMediumSelected, setImagePosterMediumSelected] = useState();

  const [imageBannerSmallUrl, setImageBannerSmallUrl] = useState("");
  const [imageBannerMediumUrl, setImageBannerMediumUrl] = useState("");
  const [imageBannerLargeUrl, setImageBannerLargeUrl] = useState("");


  const [imageBannerSmallSelected, setImageBannerSmallSelected] = useState();
  const [imageBannerMediumSelected, setImageBannerMediumSelected] = useState();
  const [imageBannerLargeSelected, setImageBannerLargeSelected] = useState();

  const [mainMediaFileSearchPhrase, setMainMediaFileSearchPhrase] = useState("");
  const [mainMediaFileSelected, setMainMediaFileSelected] = useState();
  const [mainMediaFileName, setMainMediaFileName] = useState("");

  const [subMediaFileSearchPhrase, setSubMediaFileSearchPhrase] = useState("");
  const [subMediaFileSelected, setSubMediaFileSelected] = useState();
  const [subMediaFileName, setSubMediaFileName] = useState("");

  const [mainMediaFiles, setMainMediaFiles] = useState([]);
  const [subMediaFiles, setSubMediaFiles] = useState([]);


  const [products, setProducts] = useState([]);

  const [categoriesList, setCategoriesList] = useState([]);

  const [tagsList, setTagsList] = useState([]);

  const [ageRatingList, setAgeRatingList] = useState([]);
  const [ageRatingSelectedValue, setAgeRatingSelectedValue] = useState("");

  const [castSelected, setCastSelected] = useState([]);
  const [castList, setCastList] = useState([]);
  const [personSearchPhrase, setPersonSearchPhrase] = useState("");
  const [personSearchList, setPersonSearchList] = useState([]);

  const [assetsSelected, setAssetsSelected] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [assetSearchPhrase, setAssetSearchPhrase] = useState("");
  const [assetSearchList, setAssetSearchList] = useState([]);


  const [visibilityStartDateTimestamp, setVisibilityStartDateTimestamp] = useState(0);
  const [visibilityStartDateString, setVisibilityStartDateString] = useState("");

  const [visibilityStopDateTimestamp, setVisibilityStopDateTimestamp] = useState(0);
  const [stopVisibilityDateString, setStopVisibilityDateString] = useState("");

  const [mainMediaFileStartPublishDateTimestamp, setMainMediaFileStartPublishDateTimestamp] = useState(0);
  const [mainMediaFileStartPublishDateString, setMainMediaFileStartPublishDateString] = useState("");

  const [mainMediaFileStopPublishDateTimestamp, setMainMediaFileStopPublishDateTimestamp] = useState(0);
  const [mainMediaFileStopPublishDateString, setMainMediaFileStopPublishDateString] = useState("");

  const [edit, setEdit] = useState(false);
  
  async function getCategories(initialCategories, initialTags)
  {
    if (!initialCategories) { initialCategories = [] }
    if (!initialTags) { initialTags = [] }

    const Category = Parse.Object.extend("Category");
    const query = new Parse.Query(Category);
    query.ascending("name")
    const result = await query.find()

    let categoriesListDefault = []
    let tagListDefault = []

    for (let i = 0; i < result.length; i++)
    {
      if (result[i].get("whatType") === "category")
      {
        let categoryIsActive = false 
        for (let j = 0; j < initialCategories.length; j++)
        {
          if (initialCategories[j]===result[i].id)
          {
            categoryIsActive = true
          }
        }
        categoriesListDefault.push({
          name: result[i].get("name"),
          codename: result[i].id,
          checked: categoryIsActive
        })
      }
      else if (result[i].get("whatType") === "tag")
      {
        let tagIsActive = false 
        for (let j = 0; j < initialTags.length; j++)
        {
          if (initialTags[j]===result[i].id)
          {
            tagIsActive = true
          }
        }
        tagListDefault.push({
          name: result[i].get("name"),
          codename: result[i].id,
          checked: tagIsActive
        })
      }
      setCategoriesList(categoriesListDefault)
      setTagsList(tagListDefault)


    }
  }

  async function getProductsData(assetProducts)
  {
    const result = await getProducts()
    if (assetProducts)
    {
      for (let i = 0; i < result.length; i++)
      {
        result[i].checked = false
        for (let j = 0; j < assetProducts.length; j++)
        {
          if (result[i].objectId === assetProducts[j])
          {
            result[i].checked = true
          }
        }
      }
    }
    setProducts(result)
  }

  function getAgeRating(ageRating)
  {
    const _ageRating = getConfigValue("ageRating")
    setAgeRatingList(_ageRating)
    for (let i = 0; i < _ageRating.length; i++)
    {
      if (_ageRating[i].codename === ageRating)
      {
        setAgeRatingSelectedValue(_ageRating[i].codename)
        break
      }
    }
  }

  async function getRelatedAssetsData(assetsObjectIds)
  {
    const Asset = Parse.Object.extend("Asset");
    const query = new Parse.Query(Asset);
    query.containedIn("objectId", assetsObjectIds)
    query.select("title","year")
    const result = await query.find()
    let items = []
    for (let i = 0; i < result.length; i++)
    {
      items.push({
        title: result[i].get("title"),
        year: result[i].get("year"),
        objectId: result[i].id,
      })  
    }
    setAssetsSelected(items)
  }

  async function getAssets(assetId)
  {
    const Asset = Parse.Object.extend("Asset");
    const query = new Parse.Query(Asset);
    query.include("mainMediaFile")
    query.include("subMediaFile")
    const result = await query.get(assetId)
    setTitle(result.get("title"))
    setDescription(result.get("description"))
    setShortDescription(result.get("shortDescription"))
    setWhatType(result.get("whatType"))
    setYear(result.get("year"))
    setDuration(Math.round(result.get("duration")))

    if (result.get("imagePosterSmall"))
    {
      setImagePosterSmallUrl(result.get("imagePosterSmall").url())
    }

    if (result.get("imagePosterMedium"))
    {
      setImagePosterMediumUrl(result.get("imagePosterMedium").url())
    }

    if (result.get("imageBannerSmall"))
    {
      setImageBannerSmallUrl(result.get("imageBannerSmall").url())
    }
    if (result.get("imageBannerMedium"))
    {
      setImageBannerMediumUrl(result.get("imageBannerMedium").url())
    }
    if (result.get("imageBannerLarge"))
    {
      setImageBannerLargeUrl(result.get("imageBannerLarge").url())
    }

    if (result.get("mainMediaFile"))
    {
      setMainMediaFileSelected(result.get("mainMediaFile").id)
      setMainMediaFileName(result.get("mainMediaFile").get("name"))
    }

    if (result.get("subMediaFile"))
    {
      setSubMediaFileSelected(result.get("subMediaFile").id)
      setSubMediaFileName(result.get("subMediaFile").get("name"))
    }

    setStatusValue(result.get("status"))
    getProductsData(result.get("productObjectId"))
    getCategories(result.get("categories"), result.get("tags"))
    getAgeRating(result.get("ageRating"))
    getCast(result.get("cast"))

    setVisibilityStartDateTimestamp(result.get("visibilityStartDate"))
    setVisibilityStartDateString(getTimestampAsStringForDatePicker(result.get("visibilityStartDate")))

    setVisibilityStopDateTimestamp(result.get("visibilityStopDate"))
    setStopVisibilityDateString(getTimestampAsStringForDatePicker(result.get("visibilityStopDate")))

    setMainMediaFileStartPublishDateTimestamp(result.get("mainMediaFileStartPublishDate"))
    setMainMediaFileStartPublishDateString(getTimestampAsStringForDatePicker(result.get("mainMediaFileStartPublishDate")))

    setMainMediaFileStopPublishDateTimestamp(result.get("mainMediaFileStopPublishDate"))
    setMainMediaFileStopPublishDateString(getTimestampAsStringForDatePicker(result.get("mainMediaFileStopPublishDate")))

    const relatedAssets = result.get("relatedAssets")
    if (relatedAssets && relatedAssets.length > 0)
    {
      await getRelatedAssetsData(relatedAssets)
    }
  }
  
  async function addNewAsset()
  {
    const Add = Parse.Object.extend("Asset");
    let add = new Add();
    let acl = new Parse.ACL()
    acl.setWriteAccess("*", false)
    acl.setReadAccess("*", true)
    acl.setRoleReadAccess("admin", true)
    acl.setRoleWriteAccess("admin", true)
    add.setACL(acl);

    add.set("title", title)
    add.set("whatType", whatType)
    add.set("mainMediaFileStartPublishDate", 0)
    add.set("mainMediaFileStopPublishDate", 2556057600)
    add.set("visibilityStartDate", 0)
    add.set("visibilityStopDate", 2556057600)   
    add.set("tags", [])
    add.set("cast", [])
    add.set("productObjectId", [])
    add.set("year", 0)
    add.set("duration", 0)

    setVisibilityStartDateTimestamp(0)
    setVisibilityStartDateString(getTimestampAsStringForDatePicker(0))

    setVisibilityStopDateTimestamp(2556057600)
    setStopVisibilityDateString(getTimestampAsStringForDatePicker(2556057600))

    setMainMediaFileStartPublishDateTimestamp(0)
    setMainMediaFileStartPublishDateString(getTimestampAsStringForDatePicker(0))

    setMainMediaFileStopPublishDateTimestamp(2556057600)
    setMainMediaFileStopPublishDateString(getTimestampAsStringForDatePicker(2556057600))


    add.set("status", "new")
    add.set("searchText", title.toLowerCase())

    const result =  await add.save();
    if (result.id)
    {
      setObjectId(result.id)
      getCategories([],[])
      getProductsData([])
      getAgeRating()
      setNewAssetUi(false)
    }
  }

  function afterSaved()
  {
    setIsSaving(false)
    setShowInfoLabelForSaveButton(true)
    setTimeout(function() { setShowInfoLabelForSaveButton(false) }, 2000)
  }

  async function updateAssetData()
  {
    setIsSaving(true)
    const Asset = Parse.Object.extend("Asset");
    const query = new Parse.Query(Asset);
    const asset = await query.get(objectId)

    asset.set("title", title)
    asset.set("description", description)
    asset.set("shortDescription", shortDescription)
    asset.set("searchText", title.toLowerCase()) // todo add original title
    asset.set("ageRating", ageRatingSelectedValue)
    asset.set("year", Number(year))
    asset.set("duration", Number(duration))

    let _categories = []
    for (let i = 0; i < categoriesList.length; i++)
    {
      if (categoriesList[i].checked === true)
      {
        _categories.push(categoriesList[i].codename) 
      }
    }
    asset.set("categories", _categories)

    let _tags = []
    for (let i = 0; i < tagsList.length; i++)
    {
      if (tagsList[i].checked === true)
      {
        _tags.push(tagsList[i].codename) 
      }
    }
    asset.set("tags", _tags)

    let _cast = []
    for (let i = 0; i < castSelected.length; i++)
    {
      _cast.push(castSelected[i].objectId) 
    }
    asset.set("cast", _cast)

    await asset.save()
    afterSaved()
  }

  async function updateAssetProducts()
  {
    setIsSaving(true)
    const Asset = Parse.Object.extend("Asset");
    const query = new Parse.Query(Asset);
    const asset = await query.get(objectId)
    
    let productObjectId = []
    for (let i = 0; i < products.length; i++)
    {
      if (products[i].checked === true)
      {
        productObjectId.push(products[i].objectId)
      }
    }
    asset.set("productObjectId", productObjectId)

    await asset.save()
    afterSaved()
  }

  async function updateAssetImages()
  {
    setIsSaving(true)
    const Asset = Parse.Object.extend("Asset");
    const query = new Parse.Query(Asset);
    const asset = await query.get(objectId)
    
    if (imagePosterSmallSelected)
    {
        const imageNew = new Parse.File("posterSmall.jpg", imagePosterSmallSelected);
        const imageNewResult = await imageNew.save()
        asset.set("imagePosterSmall", imageNewResult)
    }
    if (imagePosterMediumSelected)
    {
        const imageNew = new Parse.File("posterMedium.jpg", imagePosterMediumSelected);
        const imageNewResult = await imageNew.save()
        asset.set("imagePosterMedium", imageNewResult)
    }
    if (imageBannerSmallSelected)
    {
        const imageNew = new Parse.File("bannerSmall.jpg", imageBannerSmallSelected);
        const imageNewResult = await imageNew.save()
        asset.set("imageBannerSmall", imageNewResult)
    }
    if (imageBannerMediumSelected)
    {
        const imageNew = new Parse.File("bannerMedium.jpg", imageBannerMediumSelected);
        const imageNewResult = await imageNew.save()
        asset.set("imageBannerMedium", imageNewResult)
    }
    if (imageBannerLargeSelected)
    {
        const imageNew = new Parse.File("bannerLarge.jpg", imageBannerLargeSelected);
        const imageNewResult = await imageNew.save()
        asset.set("imageBannerLarge", imageNewResult)
    }

    await asset.save()
    afterSaved()
  }

  async function updateAssetAvailability()
  {
    setIsSaving(true)
    const Asset = Parse.Object.extend("Asset");
    const query = new Parse.Query(Asset);
    const asset = await query.get(objectId)
    asset.set("status", statusValue )
    asset.set("mainMediaFileStartPublishDate", mainMediaFileStartPublishDateTimestamp)
    asset.set("mainMediaFileStopPublishDate", mainMediaFileStopPublishDateTimestamp)
    asset.set("visibilityStartDate", visibilityStartDateTimestamp)
    asset.set("visibilityStopDate", visibilityStopDateTimestamp)

    await asset.save()
    afterSaved()
  }

  function onCheckboxChanged(items, setItems, codename, limit)
  {
    let _items = [...items]
    let checkedCount = 0
    for (let i = 0; i < _items.length; i++)
    {
      if (_items[i].codename === codename)
      {
        _items[i].checked = !_items[i].checked
      }
      if (_items[i].checked)
      {
        checkedCount++
      }
    }
    if (checkedCount === limit)
    {
      for (let i = 0; i < _items.length; i++)
      {
        if (!_items[i].checked)
        {
          _items[i].disabeled = true
        }
      }
    }
    else if (checkedCount < limit)
    {
      for (let i = 0; i < _items.length; i++)
      {
        _items[i].disabeled = false
      }
    }
    setItems(_items)
  }
  

  function goToAsset(assetId)
  {
    navigate("/asset/"+assetId)
  }

  function setViewAssetUi()
  {
    setNewAssetUi(false)
    setActiveTab(0)
  }

  // on input change callback
  function onTitleChange(event)
  {
    event.preventDefault();
    setTitle(event.target.value)
  }

  function onYearChange(event)
  {
    event.preventDefault();
    setYear(event.target.value)
  }

  function onDurationChange(event)
  {
    event.preventDefault();
    setDuration(event.target.value)
  }

  function onDescriptionChange(event)
  {
    event.preventDefault();
    setDescription(event.target.value)
  }

  function onShortDescriptionChange(event)
  {
    event.preventDefault();
    setShortDescription(event.target.value)
  }

  function onProductCheckboxChanged(event, objectId)
  {
    let _products = [...products]
    for (let i = 0; i < _products.length; i++)
      {
        if (_products[i].objectId === objectId)
        {
          _products[i].checked = !_products[i].checked
          break
        }
      }
    setProducts(_products)
  }

  // MEDIA FILES //
  async function findMediaFiles(phrase, setMediaFiles, streamType)
  {
    const MediaFile = Parse.Object.extend("MediaFile");
    const query = new Parse.Query(MediaFile);
    query.limit(5)
    query.contains("searchText", phrase);
    query.equalTo("whatType", streamType)
    const result = await query.find()
    console.log(phrase)
    console.log(streamType)
    console.log(result)
    let items = []
    for (let i = 0; i < result.length; i++)
    {
      items.push({
        name: result[i].get("name"),
        objectId: result[i].id,
      })  
    }

    setMediaFiles(items)
  }

  async function updateAssetMediaFiles()
  {
    setIsSaving(true)
    const Asset = Parse.Object.extend("Asset");
    const query = new Parse.Query(Asset);
    const asset = await query.get(objectId)

    if (mainMediaFileSelected && mainMediaFileSelected !== "")
    {
      const mediaFile = Parse.Object.extend("MediaFile");
      const MediaFilePointer = mediaFile.createWithoutData(mainMediaFileSelected);
      asset.set("mainMediaFile", MediaFilePointer)
    }
    else  
    {
      asset.unset("mainMediaFile");
    }


     if (subMediaFileSelected && subMediaFileSelected !== "")
    {
      const mediaFile = Parse.Object.extend("MediaFile");
      const MediaFilePointer = mediaFile.createWithoutData(subMediaFileSelected);
      asset.set("subMediaFile", MediaFilePointer)
    }
    else  
    {
      asset.unset("subMediaFile");
    }

    await asset.save()
    afterSaved()
  }

  function removeMainMediaFile()
  {
    setMainMediaFileSelected("")
    setMainMediaFileName("")
  }

  function removeSubMediaFile()
  {
    setSubMediaFileSelected("")
    setSubMediaFileName("")
  }

  function onMainMediaFileSearchChange(event)
  {
    event.preventDefault();
    setMainMediaFileSearchPhrase(event.target.value)
    if (event.target.value.length > 2)
    {
      findMediaFiles(event.target.value, setMainMediaFiles, whatType)
    }
  }

  function onSubMediaFileSearchChange(event)
  {
    event.preventDefault();
    setSubMediaFileSearchPhrase(event.target.value)
    if (event.target.value.length > 2)
    {
      findMediaFiles(event.target.value, setSubMediaFiles, "vod")
    }
  }

  // PERSON //

  async function getCast(castArray)
  {
    if (castArray && castArray.length > 0)
    {
      const queryPerson = new Parse.Query("Person");
      queryPerson.containedIn("objectId", castArray)
      const resultsPerson = await queryPerson.find()
  
      let cast = []
      for (let i = 0; i < resultsPerson.length; i++)
      {
        for (let j = 0; j < castArray.length; j++)
        {
          if (resultsPerson[i].id === castArray[j])
          {
            cast.push({name:resultsPerson[i].get("name"), surename: resultsPerson[i].get("surename"), role:resultsPerson[i].get("role"), objectId:resultsPerson[i].id})
          }
        }
      }
      setCastSelected(cast)
    }
  }

  function onPersonSearchChange(event)
  {
    event.preventDefault();
    setPersonSearchPhrase(event.target.value)
    if (event.target.value.length > 2)
    {
      findPerson(event.target.value)
    }
    else  
    {
      setPersonSearchList([])
    }
  }

  function handleAddPerson(item)
  {
    for (let i = 0; i < castSelected.length; i++)
    {
      if (castSelected[i].objectId === item.objectId)
      {
        alert(castSelected[i].name + " " + castSelected[i].surename + " is already added.")
        return
      }
    }
    let _cast = [...castSelected]
    _cast.push(item)
    setCastSelected(_cast)
    setPersonSearchList([])
    setPersonSearchPhrase("")
  }

  async function findPerson(phrase)
  {
    const Person = Parse.Object.extend("Person");
    const query = new Parse.Query(Person);
    query.limit(5)
    query.contains("searchText", phrase);
    const result = await query.find()
    let items = []
    for (let i = 0; i < result.length; i++)
    {
      items.push({
        name: result[i].get("name"),
        surename: result[i].get("surename"),
        role: result[i].get("role"),
        objectId: result[i].id,
      })  
    }
    setPersonSearchList(items)
  }

  function handleDeletePerson(personObjectId)
  {
    let _cast = [...castSelected]
    for (let i = 0; i < _cast.length; i++)
    {
      if (_cast[i].objectId === personObjectId)
      {
        _cast.splice(i, 1)
        break
      }
    }
    setCastSelected(_cast)
  }
  ////////

  // ASSET //
  async function updateAssetRelated()
  {
    setIsSaving(true)
    const Asset = Parse.Object.extend("Asset");
    const query = new Parse.Query(Asset);
    const asset = await query.get(objectId)

    let _items = []
    for (let i = 0; i < assetsSelected.length; i++)
    {
      _items.push(assetsSelected[i].objectId) 
    }
    asset.set("relatedAssets", _items)

    await asset.save()
    afterSaved()
  }

  function onAssetSearchChange(event)
  {
    event.preventDefault();
    setAssetSearchPhrase(event.target.value)
    if (event.target.value.length > 2)
    {
      findAsset(event.target.value)
    }
    else  
    {
      setAssetsList([])
    }
  }

  function handleAddAsset(item)
  {
    for (let i = 0; i < assetsSelected.length; i++)
    {
      if (assetsSelected[i].objectId === item.objectId)
      {
        alert(assetsSelected[i].title + " is already added.")
        return
      }
    }
    let _items = [...assetsSelected]
    _items.push(item)
    setAssetsSelected(_items)
    setAssetSearchList([])
    setAssetSearchPhrase("")
  }

  async function findAsset(phrase)
  {
    const Asset = Parse.Object.extend("Asset");
    const query = new Parse.Query(Asset);
    query.limit(5)
    query.contains("searchText", phrase);
    const result = await query.find()
    let items = []
    for (let i = 0; i < result.length; i++)
    {
      items.push({
        title: result[i].get("title"),
        year: result[i].get("year"),
        objectId: result[i].id,
      })  
    }
    setAssetSearchList(items)
  }

  function handleDeleteAsset(itemObjectId)
  {
    let _items = [...assetsSelected]
    for (let i = 0; i < _items.length; i++)
    {
      if (_items[i].objectId === itemObjectId)
      {
        _items.splice(i, 1)
        break
      }
    }
    setAssetsSelected(_items)
  }
  ////////

  // ON AVAILABILITY //
  function onTimeAndDateChanged(set, value)
  {
    const date = new Date(value)
    set(Math.round(date.getTime()/1000))
  }

  //

  function checkImageSize(imageUrl, definitionName, callback)
  {
    var img = new Image(); // check size of the image
    img.onload = function()
    {
      const size = getConfigValue(definitionName)
      if (size.width === this.width && size.height === this.height) // correct size!
      {
        callback(true, size)
      }
      else  // wront size
      {
        alert("zły rozmiar. Oczekiwany rozmiar to: szerokość: " + size.width+'px, wysokość: '+ size.height + "px" );
      }
    };
    img.src = imageUrl;
  }

  const imagePosterSmallHandleChange = (event) => {
    const file = event.target.files[0];
    const objectURL = URL.createObjectURL(file)

    checkImageSize(objectURL, "posterSmallSize", (isSizeCorrect, correctSize)=>{
      if (isSizeCorrect)
      {
        setImagePosterSmallSelected(file);
        setImagePosterSmallUrl(objectURL)
      }
    })
  };

  const imagePosterMediumHandleChange = (event) => {
    const file = event.target.files[0];
    const objectURL = URL.createObjectURL(file)

    checkImageSize(objectURL, "posterMediumSize", (isSizeCorrect, correctSize)=>{
      if (isSizeCorrect)
      {
        setImagePosterMediumSelected(file);
        setImagePosterMediumUrl(objectURL)
      }
    })
  };

  const imageBannerSmallHandleChange = (event) => {
    const file = event.target.files[0];
    const objectURL = URL.createObjectURL(file)
    checkImageSize(objectURL, "bannerSmallSize", (isSizeCorrect, correctSize)=>{
      if (isSizeCorrect)
      {
        setImageBannerSmallSelected(file);
        setImageBannerSmallUrl(objectURL)
      }
    })
  };

  const imageBannerMediumHandleChange = (event) => {
    const file = event.target.files[0];
    const objectURL = URL.createObjectURL(file)
    checkImageSize(objectURL, "bannerMediumSize", (isSizeCorrect, correctSize)=>{
      if (isSizeCorrect)
      {
        setImageBannerMediumSelected(file);
        setImageBannerMediumUrl(objectURL)
      }
    })
  };

  const imageBannerLargeHandleChange = (event) => {
    const file = event.target.files[0];
    const objectURL = URL.createObjectURL(file)
    checkImageSize(objectURL, "bannerLargeSize", (isSizeCorrect, correctSize)=>{
      if (isSizeCorrect)
      {
        setImageBannerLargeSelected(file);
        setImageBannerLargeUrl(objectURL)
      }
    })
  };


  const onSelectChange = (event: SelectChangeEvent) => {
    // setAge(event.target.value as string);
    setStatusValue(event.target.value)
  };

  const onAgeRatingSelectChange = (event: SelectChangeEvent) => {
    setAgeRatingSelectedValue(event.target.value)
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => 
  {
    if (asset_id === "add_new_vod")
    {
      setWhatType("vod")
      setNewAssetUi(true)
    }
    else if (asset_id === "add_new_live")
    {
      setWhatType("live")
      setNewAssetUi(true)
    }
    else
    {
      setObjectId(asset_id)
      setViewAssetUi()
      getAssets(asset_id)
    }
  }, [])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <NavBar title={whatType + ": " + title} showBackButton={true} />
        <Box
          component="nav"
          sx={{ width: { sm: sideMenuWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <SideMenu/>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${sideMenuWidth}px)` } }}
        >
          <Toolbar/>
          <Grid>     
              {newAssetUi?<> {/* NEW */}
                  <Grid container>
                    <Grid item>
                      <Button variant="text" onClick={addNewAsset}>add</Button>
                    </Grid>
                  </Grid>    
                  <p>Define title and press ADD to start editing new asset.</p>   
                  <Grid style={rowStyle}>
                    <TextField style={{width:"640px"}} id="title" label="title" variant="outlined" value={title} onChange={onTitleChange} />
                  </Grid>
                </>:<> 
                  <Tabs value={activeTab} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="overview" />
                    <Tab label="metadata" />
                    <Tab label="images" />
                    <Tab label="products" />
                    <Tab label="video files" />
                    <Tab label="availability" />
                    <Tab label="related" />
                  </Tabs>

                  <TabPanel value={activeTab} index={0}>
                      <MuiAlert style={{width:"150px"}} severity={statusValue=="published"?"success":"warning"}>{statusValue}</MuiAlert>
                      <h1>{title}</h1>
                      <p>{description}</p>

                      {imagePosterSmallUrl?<Grid>
                        <Grid>
                          Plakat
                        </Grid>
                        <Grid>
                           <img
                            src={imagePosterSmallUrl}
                            width="205px"
                            height="290px"
                            alt="poster"
                            style={{width:"205px", height:"290px"}}
                          />
                        </Grid>
                      </Grid>:null}

                      {imageBannerSmallUrl?<Grid>
                        <Grid>
                          Banner
                        </Grid>
                        <Grid>
                           <img
                            src={imageBannerSmallUrl}
                            width="480px"
                            height="270px"
                            alt="poster"
                            style={{width:"480px", height:"270px"}}
                          />
                        </Grid>
                      </Grid>:null}
                    
                  </TabPanel>

                  <TabPanel value={activeTab} index={1}>
                    <Grid> 
                        <Grid container style={rowTitle}>
                          <Grid item>
                            <CustomButton buttonTitle={"save metadata"} onClick={updateAssetData} showInfo={showInfoLabelForSaveButton} showLoader={isSaving} alertMessage={"saved"}/>
                          </Grid>
                        </Grid>
                        
                        <Grid style={rowStyle}>
                          <TextField style={{width:"640px"}} id="title" label="title" variant="outlined" value={title} onChange={onTitleChange} />
                        </Grid>
                        
                        <Grid style={rowStyle}>
                          <TextField style={{width:"640px"}} multiline maxRows={6} id="description" label="full description" variant="outlined" value={description} onChange={onDescriptionChange} />
                        </Grid>

                        <Grid style={rowStyle}>
                          <TextField style={{width:"640px"}} multiline maxRows={3} id="short-description" label="short description (mobile and banners)" variant="outlined" value={shortDescription} onChange={onShortDescriptionChange} />
                        </Grid>

                        {ageRatingList.length>0?<Grid style={rowStyle}>
                          <FormControl fullWidth>
                            <InputLabel  id="demo-simple-select-label">Age Rating</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={ageRatingSelectedValue}
                              label="Age Rating"
                              onChange={onAgeRatingSelectChange}
                              style={{width:"640px"}}
                            >
                              {ageRatingList.map((item) => (
                                <MenuItem value={item.codename}>{item.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>:null}
                        
                        <Grid style={rowStyle}>
                          <TextField style={{width:"160px"}} id="year" type="number" label="year" variant="outlined" value={year} onChange={onYearChange} />
                        </Grid>

                        <Grid style={rowStyle}>
                          <TextField style={{width:"160px"}} id="duration" type="number" label="duration in minutes" variant="outlined" value={duration} onChange={onDurationChange} />
                        </Grid>

                        <Grid container spacing={{ xs: 2 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                          <Grid item xs={1}>
                            Categories
                            <FormHelperText>add max 2.</FormHelperText>
                          </Grid>
                          <Grid item xs={5}>
                            {categoriesList.map((item) => ( 
                                <FormControlLabel key={item.codename} control={<Checkbox checked={item.checked} disabled={item.disabeled} onChange={(e)=>{onCheckboxChanged(categoriesList, setCategoriesList, item.codename, 2)} } />} label={item.name} />                           
                            ) )}
                          </Grid>
                        </Grid>

                        <Grid container spacing={{ xs: 2 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                          <Grid item xs={1}>
                            Tags
                            <FormHelperText>add to improve recommendations</FormHelperText>
                          </Grid>
                          <Grid item xs={5}>
                            {tagsList.map((item) => ( 
                                <FormControlLabel key={item.codename} control={<Checkbox checked={item.checked} disabled={item.disabeled} onChange={(e)=>{onCheckboxChanged(tagsList, setTagsList, item.codename, 2)} } />} label={item.name} />                           
                            ) )}
                          </Grid>
                        </Grid>

                        <Grid container spacing={{ xs: 2 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                          <Grid item xs={2}>
                            <div>Cast</div>
                            <TextField id="outlined-basic" label="type in name or surename" variant="outlined" value={personSearchPhrase} onChange={onPersonSearchChange} />
                            <FormControl >
                              <InputLabel shrink htmlFor="select-multiple-native">
                                select
                              </InputLabel>
                              {personSearchList.length>0?<Select
                                multiple
                                native
                                label="Native"
                                inputProps={{
                                  id: 'sub-media-file',
                                }}
                              >
                                {personSearchList.map((item) => (
                                  <option key={item.objectId} value={item.name} onClick={()=>{handleAddPerson(item); setSubMediaFileName(item.name)}}>
                                    {item.name + " " + item.surename + " | " + item.role}
                                  </option>
                                ))}
                              </Select>:null}
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <Stack direction="row" spacing={1}>
                              {castSelected.map((item) => ( 
                                  <Chip label={item.name + " " + item.surename + " | " + item.role} onDelete={()=>{handleDeletePerson(item.objectId)} } key={item.objectId}/>                           
                              ) )}
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                  </TabPanel>

                  <TabPanel value={activeTab} index={2}>
                      <Grid> 
                        <Grid container style={rowStyle}>
                          <Grid item>
                            <CustomButton buttonTitle={"save images"} onClick={updateAssetImages} showInfo={showInfoLabelForSaveButton} showLoader={isSaving} alertMessage={"saved"}/>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item xs={4}>
                             <Button variant="outlined" component="label">
                                set poster small {defaultImagesSizePosterSmall.width}x{defaultImagesSizePosterSmall.height}px jpg
                                <input hidden accept=".jpg, .jpeg" type="file" onChange={imagePosterSmallHandleChange} />
                              </Button>
        
                              {imagePosterSmallUrl?<Grid item>
                                 <img
                                  src={imagePosterSmallUrl}
                                  width="144px"
                                  height="204px"
                                  alt="poster"
                                  style={{width:"144px", height:"204px"}}
                                />
                              </Grid>:null}
                            </Grid>

                            <Grid item xs={4}>
                             <Button variant="outlined" component="label">
                                set poster medium {defaultImagesSizePosterMedium.width}x{defaultImagesSizePosterMedium.height}px jpg
                                <input hidden accept=".jpg, .jpeg" type="file" onChange={imagePosterMediumHandleChange} />
                              </Button>
        
                              {imagePosterMediumUrl?<Grid item>
                                 <img
                                  src={imagePosterMediumUrl}
                                  width="205px"
                                  height="290px"
                                  alt="poster"
                                  style={{width:"205px", height:"290px"}}
                                />
                              </Grid>:null}
                            </Grid>
                        </Grid>

                        <Grid container >
                          <Grid item xs={4}>
                            <Button variant="outlined" component="label">
                              set small banner {defaultImagesSizeBannerSmall.width}x{defaultImagesSizeBannerSmall.height}px jpg
                              <input hidden accept=".jpg, .jpeg" type="file" onChange={imageBannerSmallHandleChange} />
                            </Button>
               
                            {imageBannerSmallUrl?<Grid item>
                               <img
                                src={imageBannerSmallUrl}
                                width="240px"
                                height="135px"
                                alt="poster"
                                style={{width:"240px", height:"135px"}}
                              />
                            </Grid>:null}
                          </Grid>

                          <Grid item xs={4}>
                            <Button variant="outlined" component="label">
                              set medium banner {defaultImagesSizeBannerMedium.width}x{defaultImagesSizeBannerMedium.height}px jpg
                              <input hidden accept=".jpg, .jpeg" type="file" onChange={imageBannerMediumHandleChange} />
                            </Button>

                            {imageBannerMediumUrl?<Grid item>
                               <img
                                src={imageBannerMediumUrl}
                                width="280px"
                                height="158px"
                                alt="poster"
                                style={{width:"280px", height:"158px"}}
                              />
                            </Grid>:null}
                          </Grid>

                          <Grid item xs={4}>
                            <Button variant="outlined" component="label">
                              set large banner {defaultImagesSizeBannerLarge.width}x{defaultImagesSizeBannerLarge.height}px jpg
                              <input hidden accept=".jpg, .jpeg" type="file" onChange={imageBannerLargeHandleChange} />
                            </Button>

                            {imageBannerLargeUrl?<Grid item>
                               <img
                                src={imageBannerLargeUrl}
                                width="320px"
                                height="180px"
                                alt="poster"
                                style={{width:"320px", height:"180px"}}
                              />
                            </Grid>:null}
                          </Grid>
                        </Grid>
                      </Grid>
                  </TabPanel>

                  <TabPanel value={activeTab} index={3}>
                    <Grid> 
                      <Grid container style={rowTitle}>
                        <Grid item>
                          <CustomButton buttonTitle={"save products"} onClick={updateAssetProducts} showInfo={showInfoLabelForSaveButton} showLoader={isSaving} alertMessage={"saved"}/>
                        </Grid>
                      </Grid>
                      <Grid>
                          <div style={rowTitle}>Products list</div>
                          {products.map( (item) => (
                              <Grid item>
                                <FormControlLabel control={<Checkbox checked={item.checked} onChange={(e)=>{onProductCheckboxChanged(e, item.objectId)} } />} label={item.name} />
                              </Grid>
                          ) )}
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel value={activeTab} index={4}>
                    <Grid> 
                      <Grid container style={rowTitle}>
                        <Grid item>
                          <CustomButton buttonTitle={"save mediafiles"} onClick={updateAssetMediaFiles} showInfo={showInfoLabelForSaveButton} showLoader={isSaving} alertMessage={"saved"}/>
                        </Grid>
                      </Grid>

                      <Grid container style={rowStyle}>
                        <Grid item xs={4}>
                          <TextField id="outlined-basic" label="search for main video file" variant="outlined" value={mainMediaFileSearchPhrase} onChange={onMainMediaFileSearchChange} />
                          <FormControl >
                            <InputLabel shrink htmlFor="select-multiple-native">
                              select main video file
                            </InputLabel>
                            {mainMediaFiles.length>0?<Select
                              multiple
                              native
                              label="Native"
                              inputProps={{
                                id: 'main-media-file',
                              }}
                            >
                              {mainMediaFiles.map((item) => (
                                <option key={item.objectId} value={item.name} onClick={()=>{setMainMediaFileSelected(item.objectId); setMainMediaFileName(item.name); setMainMediaFileSearchPhrase(""); setMainMediaFiles([]) }}>
                                  {item.name}
                                </option>
                              ))}
                            </Select>:null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <b>Selected:</b> {mainMediaFileName!==""?<Chip label={mainMediaFileName} onDelete={removeMainMediaFile} key={mainMediaFileName}/>:null}
                        </Grid>
                      </Grid>

                      <Grid container style={rowStyle}>
                        <Grid item xs={4}>
                          <TextField id="outlined-basic" label="search for sub video file" variant="outlined" value={subMediaFileSearchPhrase} onChange={onSubMediaFileSearchChange} />
                          <FormControl >
                            <InputLabel shrink htmlFor="select-multiple-native">
                              select sub video file
                            </InputLabel>
                            {subMediaFiles.length>0?<Select
                              multiple
                              native
                              label="Native"
                              inputProps={{
                                id: 'sub-media-file',
                              }}
                            >
                              {subMediaFiles.map((item) => (
                                <option key={item.objectId} value={item.name} onClick={()=>{setSubMediaFileSelected(item.objectId); setSubMediaFileName(item.name); setSubMediaFileSearchPhrase(""); setSubMediaFiles([]) }}>
                                  {item.name}
                                </option>
                              ))}
                            </Select>:null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <b>Selected:</b> {subMediaFileName!==""?<Chip label={subMediaFileName} onDelete={removeSubMediaFile} key={subMediaFileName}/>:null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel value={activeTab} index={5}>
                    <Grid> 
                      <Grid container style={rowTitle}>
                        <Grid item>
                          <CustomButton buttonTitle={"save availability"} onClick={updateAssetAvailability} showInfo={showInfoLabelForSaveButton} showLoader={isSaving} alertMessage={"saved"}/>
                        </Grid>
                      </Grid>
                      
                      <Grid container style={rowStyle}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Status</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={statusValue}
                            label="Status"
                            onChange={onSelectChange}
                          >
                            <MenuItem value={"new"}>New</MenuItem>
                            <MenuItem value={"unpublished"}>Unpublished</MenuItem>
                            <MenuItem value={"published"}>Published</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid container style={rowStyle}>
                        <Grid item>
                          <TextField
                            id="datetime-local"
                            label="visibility START date"
                            type="datetime-local"
                            defaultValue={visibilityStartDateString}
                            sx={{ width: 250 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(event)=>{ onTimeAndDateChanged(setVisibilityStartDateTimestamp, event.target.value) }}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            id="datetime-local"
                            label="visibility STOP date "
                            type="datetime-local"
                            defaultValue={stopVisibilityDateString}
                            sx={{ width: 250 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(event)=>{ onTimeAndDateChanged(setVisibilityStopDateTimestamp, event.target.value) }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container style={rowStyle}>
                        <Grid item>
                          <TextField
                            id="datetime-local"
                            label="stream availability START date"
                            type="datetime-local"
                            defaultValue={mainMediaFileStartPublishDateString}
                            sx={{ width: 250 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(event)=>{ onTimeAndDateChanged(setMainMediaFileStartPublishDateTimestamp, event.target.value) }}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            id="datetime-local"
                            label="stream availability STOP date "
                            type="datetime-local"
                            defaultValue={mainMediaFileStopPublishDateString}
                            sx={{ width: 250 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(event)=>{ onTimeAndDateChanged(setMainMediaFileStopPublishDateTimestamp, event.target.value) }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel value={activeTab} index={6}>
                    <Grid>
                      <Grid container style={rowTitle}>
                        <Grid item>
                          <CustomButton buttonTitle={"save related"} onClick={updateAssetRelated} showInfo={showInfoLabelForSaveButton} showLoader={isSaving} alertMessage={"saved"}/>
                        </Grid>
                      </Grid>
                      <Grid container spacing={{ xs: 2 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                        <Grid item xs={2}>
                          <div>If nothing is defined, related will be based on asset category.</div>
                          <h2>Assets</h2>
                          <TextField id="outlined-basic" label="type in asset title" variant="outlined" value={assetSearchPhrase} onChange={onAssetSearchChange} />
                          <FormControl >
                            <InputLabel shrink htmlFor="select-multiple-native">
                              select
                            </InputLabel>
                            {assetSearchList.length>0?<Select
                              multiple
                              native
                              label="Native"
                              inputProps={{
                                id: 'sub-media-file',
                              }}
                            >
                              {assetSearchList.map((item) => (
                                <option key={item.objectId} value={item.name} onClick={()=>{handleAddAsset(item);}}>
                                  {item.title + " | " + item.year}
                                </option>
                              ))}
                            </Select>:null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <Stack direction="row" spacing={1}>
                            {assetsSelected.map((item) => ( 
                                <Chip label={item.title + " | " + item.year} onDelete={()=>{handleDeleteAsset(item.objectId)} } key={item.objectId}/>                           
                            ) )}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>                
                </>}
          </Grid>
        </Box>
      </Box>
    </>
  );
}