import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// init 
import "./custom/config";
import { alloweOnlyLoggedInUsers } from "./services/user"

import Authenticate from './pages/Authenticate';
import Dashboard from './pages/Dashboard';
import Movies from './pages/Movies';
import Events from './pages/Events';
import TvChannels from './pages/TvChannels';
import TvChannel from './pages/TvChannel';
import Asset from './pages/Asset';
import Pages from './pages/Pages';
import Lists from './pages/Lists';
import List from './pages/List';
import Categories from './pages/Categories';
import Products from './pages/Products';
import Product from './pages/Product';
import People from './pages/People';
import CloudVideoKit from './pages/CloudVideoKit';
import Sales from './pages/Sales';
import EndUsers from './pages/EndUsers';
import EndUser from './pages/EndUser';



import { createGlobalStyle } from "styled-components"
import CookieConsent from 'react-cookie-consent-notification';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import Parse from 'parse';

// import TagManager from 'react-gtm-module'

import {color_primary, color_accent, main_font, deployment_type} from "./styles/globalstyle";

Parse.initialize("q8eE30siX4LIMvZ7dKJxsS7vO11p4FqZjPXI4b6M", "NysMqdAtcim4JndsFl3rIX1huJllnUmtnGeHreW9");
Parse.serverURL = "https://streamingapp.b4a.io/";
Parse.enableLocalDatastore()

const theme = createTheme({
  // typography: {
  //   fontFamily: [
  //     'roboto'
  //   ],
  // },
  // typography: {
  //   h1: {
  //     color: 'red',
  //     font
  //   },
  //   // Disable h3 variant
  //   h3: undefined,
  // },
  // palette: {
  //   primary: {
  //     // main: "#nnnnnn",
  //   },
  // },
});

export default function App({children}) 
{
  alloweOnlyLoggedInUsers()
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {children}
      </LocalizationProvider>
      <Router>
        <ThemeProvider theme={theme}>
        {/*<GlobalStyle/>*/}
          <div>
            <Routes>
              <Route path="/" exact element={<Authenticate/>} />
              <Route path="/authenticate" exact element={<Authenticate/>} />
              <Route path="/dashboard" exact element={<Dashboard/>} />
              <Route path="/vods" exact element={<Movies/>} />
              <Route path="/events" exact element={<Events/>} />
              <Route path="/tvchannels" exact element={<TvChannels/>} />
              <Route path="/tvchannels/:channel_id" exact element={<TvChannel/>} />
              <Route path="/asset/:asset_id" exact element={<Asset/>} />
              <Route path="/pages" exact element={<Pages/>} />
              <Route path="/pages/lists/:page_id" exact element={<Lists/>} />
              <Route path="/pages/lists/list/:list_id" exact element={<List/>} />
              <Route path="/pages/page/:page_id" exact element={<Lists/>} />
              <Route path="/metadata/:page_id" exact element={<Categories/>} />
              <Route path="/metadata/people" exact element={<People/>} />
              <Route path="/settings/cloudvideokit" exact element={<CloudVideoKit/>} />
              <Route path="/finance/products/:product_id" exact element={<Product/>} />
              <Route path="/finance/products" exact element={<Products/>} />
              <Route path="/finance/sales" exact element={<Sales/>} />
              <Route path="/users" exact element={<EndUsers/>} />
              <Route path="/users/:user_id" exact element={<EndUser/>} />
            </Routes>
          </div>
        </ThemeProvider>
      </Router>
      <CookieConsent 
        background={'#ffffff'}
        bottomPosition={true}
        buttonText={'OK'}
        buttonBackground={'#000000'}
        buttonColor={'#ffffff'}
        buttonFontSize={16}
        color={'#000000'}
        // consentFunction={checkStatus}
        padding={20}
      >
        Ta strona wykorzystuje pliki cookie, aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie. Zobacz: <a href={''} style={{ color: '#000000' }}>Polityka prywatności</a>
      </CookieConsent>
    </>
  );
}
