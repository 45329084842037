import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu } from "../components/sidemenu"
// import { Chart } from "../components/chart"
import UsersTable from "../components/usersTable"

import Parse from "parse";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export default function Authenticate() 
{
  const navigate = useNavigate()  
  const [items, setItems] = useState([]);
  const [todayTotalValue, setTodayTotalValue] = useState(0);
  const [todayDateString, setTodayDateString] = useState("");
  const [searchPhrase, setSearchPhrase] = useState("");
  const [searchSafeTimer, setSearchSafeTimer] = useState()
  const [isLoading, setIsLoading] = useState(false);

  
  async function getItems(phrase)
  {
    let date = new Date();
    const queryDate = {
      start_month: date.getMonth()+1,
      start_day: date.getDate(),
      start_year: date.getFullYear(),
      end_month: date.getMonth()+1,
      end_day: date.getDate(),
      end_year: date.getFullYear()
    }

    const start_date = new Date(queryDate.start_month.toString() + " " + queryDate.start_day.toString() + " " + queryDate.start_year.toString() + " 0:00 UTC");
    const end_date = new Date(queryDate.end_month.toString() + " " + queryDate.end_day.toString() + " " + queryDate.end_year.toString() + " 23:59:59 UTC");

    const User = Parse.Object.extend("User");
    const query = new Parse.Query(User);
    query.startsWith("username", phrase)
    const result = await query.find()

    let _items = []
    for (let i = 0; i < result.length; i++)
    {
      _items.push({
        objectId: result[i].id,
        id: result[i].id,
        name: result[i].get("username"),   
        email: result[i].get("email"),
        status: result[i].get("status"),
      })
    }

    setTodayDateString(date.toLocaleDateString())
    setTodayTotalValue(_items.length)
    setItems(_items)
  }

  function onSearchChange(event)
  {
    event.preventDefault();
    clearTimeout(searchSafeTimer);
    setItems([])
    setSearchPhrase(event.target.value)
    setSearchSafeTimer(setTimeout(function(){
      if (event.target.value.length > 2)
      {
        getItems(event.target.value)
      }
      else if (event.target.value.length === 0)
      {
        getItems([])
      }
    }, 1000) )

    if (event.target.value.length > 2)
    {
      setIsLoading(true)
    }
    else 
    {
      setIsLoading(false)
    }
  }

  useEffect(() => 
  {
    // getItems()
  }, [])

  return (
    <>
     <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <NavBar/>
        <SideMenu/>
      </Box>
      <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            marginLeft:"240px"
          }}
        >
        <Toolbar />

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <TextField style={{width:"240px", marginBottom:"16px", marginLeft:"16px"}} id="search" label="search" variant="outlined" value={searchPhrase} onChange={onSearchChange} />
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <UsersTable items={items}/>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}