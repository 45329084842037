import * as React from 'react';
import Parse from 'parse';
import { useState, useRef, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu, sideMenuWidth } from "../components/sidemenu"

import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { getConfigValue } from "../custom/config"
import { getMenuConfig } from "../services/config"

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function List() 
{
  const { list_id } = useParams();

  const navigate = useNavigate()  

  const defaultImagesSizePosterSmall = getConfigValue("posterSmallSize")
  const defaultImagesSizeBannerSmall = getConfigValue("bannerSmallSize")
  const defaultImagesSizeBannerMedium = getConfigValue("bannerMediumSize")
  const defaultImagesSizeBannerLarge = getConfigValue("bannerLargeSize")

  const [activeTab, setActiveTab] = React.useState(0);

  const [showInputError, setShowInputError] = useState(false);
  const [showPagesError, setShowPagesError] = useState(false);

  const [name, setName] = useState("");

  const [objectId, setObjectId] = useState("");
  const [listIsActive, setListIsActive] = useState(true);

  const [assetTypeValue, setAssetTypeValue] = useState("");
  const [assetTypeIsActive, setAssetTypeIsActive] = useState(true);
  const [assetTypeList, setAssetTypeList] = useState([]);

  const [whatTypeValue, setWhatTypeValue] = useState("");
  const [whatTypeList, setWhatTypeList] = useState([]);
  
  const [tileTypeValue, setTileTypeValue] = useState("poster");
  const [tileTypeList, setTileTypeList] = useState([]);

  const [pages, setPages] = useState([]);

  const [tagsList, setTagsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);

  const [assetsSelected, setAssetsSelected] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [assetSearchPhrase, setAssetSearchPhrase] = useState("");
  const [assetSearchList, setAssetSearchList] = useState([]);

  const [imageBannerSmallUrl, setImageBannerSmallUrl] = useState("");
  const [imageBannerMediumUrl, setImageBannerMediumUrl] = useState("");
  const [imageBannerLargeUrl, setImageBannerLargeUrl] = useState("");

  const [imageBannerSmallSelected, setImageBannerSmallSelected] = useState();
  const [imageBannerMediumSelected, setImageBannerMediumSelected] = useState();
  const [imageBannerLargeSelected, setImageBannerLargeSelected] = useState();



  async function getCategories(initialCategories, initialTags)
  {
    const Category = Parse.Object.extend("Category");
    const query = new Parse.Query(Category);
    query.ascending("name")
    const result = await query.find()
 
    let categoriesListDefault = []
    let tagListDefault = []


    for (let i = 0; i < result.length; i++)
    {
      if (result[i].get("whatType") === "category")
      {
        let categoryIsActive = false 
        for (let j = 0; j < initialCategories.length; j++)
        {
          if (initialCategories[j]===result[i].id)
          {
            categoryIsActive = true
          }
        }
        categoriesListDefault.push({
          name: result[i].get("name"),
          codename: result[i].id,
          checked: categoryIsActive
        })
      }
      else if (result[i].get("whatType") === "tag")
      {
        let tagIsActive = false 
        for (let j = 0; j < initialTags.length; j++)
        {
          if (initialTags[j]===result[i].id)
          {
            tagIsActive = true
          }
        }
        tagListDefault.push({
          name: result[i].get("name"),
          codename: result[i].id,
          checked: tagIsActive
        })
      }

    }
    setCategoriesList(categoriesListDefault)
    setTagsList(tagListDefault)
  }

  async function setDefaultValues(assetType, whatType, tileType, initialCategories, listPages, initialTags)
  {
    if (!initialCategories)
    {
      initialCategories = []
    }
    if (!initialTags)
    {
      initialTags = []
    }
    // get config items.
    const assetTypeListConfig = getConfigValue("assetTypes")
    let assetTypeListDefault = []
    for (let i = 0; i < assetTypeListConfig.length; i++)
    {
      assetTypeListDefault.push({
        name: assetTypeListConfig[i].name,
        codename: assetTypeListConfig[i].codename,
        checked: false
      })
    }
    if (!assetType)
    {
      assetType = {
        isActive: true,
        value: assetTypeListDefault[0].codename
      }
    }
    setAssetTypeIsActive(assetType.isActive) // set on/off value for component
    onRadiobuttonChanged(assetTypeListDefault, setAssetTypeList, setAssetTypeValue, assetType.value) // update all walues for settings and for UI

    const whatTypeListConfig = getConfigValue("listTypes")
    let whatTypeListDefault = []
    for (let i = 0; i < whatTypeListConfig.length; i++)
    {
      whatTypeListDefault.push({
        name: whatTypeListConfig[i].name,
        codename: whatTypeListConfig[i].codename,
        checked: false
      })
    }
    if (!whatType)
    {
      whatType = whatTypeListDefault[0].codename
    }
    setWhatTypeList(whatTypeListDefault)
    onRadiobuttonChanged(whatTypeListDefault, setWhatTypeList, setWhatTypeValue, whatType)

    const tileTypeListConfig = getConfigValue("tileTypes")
    let tileTypeListDefault = []
    for (let i = 0; i < tileTypeListConfig.length; i++)
    {
      tileTypeListDefault.push({
        name: tileTypeListConfig[i].name,
        codename: tileTypeListConfig[i].codename,
        checked: false
      })
    }
    if (!tileType)
    {
      tileType = tileTypeListDefault[0].codename
    }
    setTileTypeList(tileTypeListDefault)
    onRadiobuttonChanged(tileTypeListDefault, setTileTypeList, setTileTypeValue, tileType)

    getCategories(initialCategories, initialTags)


    const menuItems = await getMenuConfig()
    let _menuItems = []
    for (let i = 0; i < menuItems.length; i++)
    {
      if (menuItems[i].whatType === "lists" || menuItems[i].whatType === "grid")
      {
        _menuItems.push(menuItems[i])
      }
    }
    for (let i = 0; i < _menuItems.length; i++)
    {
      _menuItems[i].checked = false
      if (listPages)
      {
        for (let j = 0; j < listPages.length; j++)
        {
          if (_menuItems[i].codename === listPages[j])
          {
            _menuItems[i].checked = true
          }
        }
      }
    }
    setPages(_menuItems)
  }

  async function getList(listObjectId)
  {
    const List = Parse.Object.extend("List");
    const query = new Parse.Query(List);
    const result = await query.get(listObjectId)

    setName(result.get("name"))
    setObjectId(result.id)
    setListIsActive(result.get("isActive"))

    const assets = result.get("assets")
    if (assets && assets.length > 0)
    {
      await getAssetsData(assets)
    }

    const imageBannerLarge = result.get("imageBannerLarge")
    if (imageBannerLarge)
    {
      setImageBannerLargeUrl(imageBannerLarge.url())
    }
    const imageBannerMedium = result.get("imageBannerMedium")
    if (imageBannerMedium)
    {
      setImageBannerMediumUrl(imageBannerMedium.url())
    }
    const imageBannerSmall = result.get("imageBannerSmall")
    if (imageBannerSmall)
    {
      setImageBannerSmallUrl(imageBannerSmall.url())
    }

    // get config items.
    setDefaultValues(result.get("assetType"), result.get("whatType"), result.get("tileType"), result.get("categories"), result.get("pageCodenames"), result.get("tags"))
    
  }
  
  async function addNewItem()
  {
    let _pages = []
    for (let i = 0; i < pages.length; i++)
    {
      if (pages[i].checked)
      {
        _pages.push(pages[i].codename)
      }
    }
    if (_pages.length > 0)
    {
      setShowPagesError(false)

      const Add = Parse.Object.extend("List");
      let add = new Add;
      let acl = new Parse.ACL()
      acl.setWriteAccess("*", false)
      acl.setReadAccess("*", false)
      acl.setRoleReadAccess("admin", true)
      acl.setRoleWriteAccess("admin", true)
      add.setACL(acl);

      add.set("assetType",{
        isActive: assetTypeIsActive,
        value: assetTypeValue
      })

      if (tileTypeValue === "poster")
      {
        add.set("assetLimit", 6)
      }
      else if (tileTypeValue === "image")
      {
        add.set("assetLimit", 4)
      }
      else 
      {
        add.set("assetLimit", 5)
      }
      add.set("tileType", tileTypeValue) // set
      add.set("whatType", whatTypeValue) // set
      add.set("isActive", listIsActive) // set
      add.set("sortIndex", 1)
      add.set("name", name) // set
      add.set("sort", {"isActive":false,"field":"createdAt","direction":"ascending"})
      add.set("pageCodenames",_pages)

      let _tags = []
      for (let i=0; i<tagsList.length; i++)
      {
        if (tagsList[i].checked === true)
        {
          _tags.push(tagsList[i].codename)
        }
      }
      add.set("tags", _tags) 

      let _categories = []
      for (let i=0; i<categoriesList.length; i++)
      {
        if (categoriesList[i].checked === true)
        {
          _categories.push(categoriesList[i].codename)
        }
      }
      add.set("categories", _categories)   

      const result =  await add.save();
      if (result.id)
      {
        setObjectId(result.id)
        navigate(-1)
      }
    }
    else  
    {
      setShowPagesError(true)
    }
  }

  async function updateItemData()
  {
    let _pages = []
    for (let i = 0; i < pages.length; i++)
    {
      if (pages[i].checked)
      {
        _pages.push(pages[i].codename)
      }
    }

    if (_pages.length > 0)
    {
      setShowPagesError(false)
      const List = Parse.Object.extend("List");
      const query = new Parse.Query(List);
      const result = await query.get(objectId)

      result.set("name", name)
      result.set("whatType", whatTypeValue)
      result.set("tileType", tileTypeValue)
      result.set("isActive", listIsActive)
      
      result.set("assetType",{
        isActive: assetTypeIsActive,
        value: assetTypeValue
      })

      result.set("pageCodenames",_pages)

      let _tags = []
      for (let i=0; i<tagsList.length; i++)
      {
        if (tagsList[i].checked === true)
        {
          _tags.push(tagsList[i].codename)
        }
      }
      result.set("tags", _tags) 

      let _categories = []
      for (let i=0; i<categoriesList.length; i++)
      {
        if (categoriesList[i].checked === true)
        {
          _categories.push(categoriesList[i].codename)
        }
      }
      result.set("categories", _categories)      

      let _assets = []
      for (let i = 0; i < assetsSelected.length; i++)
      {
        _assets.push(assetsSelected[i].objectId) 
      }
      result.set("assets", _assets)

      await result.save()
      setViewUiFunction()
    }
    else 
    {
      setShowPagesError(true)
    }
  }

  async function updateItemImages()
  {
    const List = Parse.Object.extend("List");
    const query = new Parse.Query(List);
    const asset = await query.get(objectId)
    
    if (imageBannerSmallSelected)
    {
        const imageNew = new Parse.File("bannerSmall.jpg", imageBannerSmallSelected);
        const imageNewResult = await imageNew.save()
        asset.set("imageBannerSmall", imageNewResult)
    }
    if (imageBannerMediumSelected)
    {
        const imageNew = new Parse.File("bannerMedium.jpg", imageBannerMediumSelected);
        const imageNewResult = await imageNew.save()
        asset.set("imageBannerMedium", imageNewResult)
    }
    if (imageBannerLargeSelected)
    {
        const imageNew = new Parse.File("bannerLarge.jpg", imageBannerLargeSelected);
        const imageNewResult = await imageNew.save()
        asset.set("imageBannerLarge", imageNewResult)
    }

    await asset.save()
    setViewUiFunction()
  }

  function checkImageSize(imageUrl, definitionName, callback)
  {
    var img = new Image(); // check size of the image
    img.onload = function()
    {
      const size = getConfigValue(definitionName)
      if (size.width === this.width && size.height === this.height) // correct size!
      {
        callback(true, size)
      }
      else  // wront size
      {
        alert("zły rozmiar. Oczekiwany rozmiar to: szerokość: " + size.width+'px, wysokość: '+ size.height + "px" );
      }
    };
    img.src = imageUrl;
  }

  const imageBannerSmallHandleChange = (event) => {
    const file = event.target.files[0];
    const objectURL = URL.createObjectURL(file)
    checkImageSize(objectURL, "bannerSmallSize", (isSizeCorrect, correctSize)=>{
      if (isSizeCorrect)
      {
        setImageBannerSmallSelected(file);
        setImageBannerSmallUrl(objectURL)
      }
    })
  };

  const imageBannerMediumHandleChange = (event) => {
    const file = event.target.files[0];
    const objectURL = URL.createObjectURL(file)
    checkImageSize(objectURL, "bannerMediumSize", (isSizeCorrect, correctSize)=>{
      if (isSizeCorrect)
      {
        setImageBannerMediumSelected(file);
        setImageBannerMediumUrl(objectURL)
      }
    })
  };

  const imageBannerLargeHandleChange = (event) => {
    const file = event.target.files[0];
    const objectURL = URL.createObjectURL(file)
    checkImageSize(objectURL, "bannerLargeSize", (isSizeCorrect, correctSize)=>{
      if (isSizeCorrect)
      {
        setImageBannerLargeSelected(file);
        setImageBannerLargeUrl(objectURL)
      }
    })
  };

  async function onSaveButtonClicked()
  {
    if (name === "")
    {
      setShowInputError(true)
    }
    else 
    {
      setShowInputError(false)
      if (list_id === "add_new")
      {
        addNewItem()
      }
      else  
      {
        updateItemData()
      }
    }
  }


  function setEditUiFunction()
  {
    setActiveTab(1)
  }

  function setViewUiFunction()
  {
    setActiveTab(0)
  }

  // on input change callback
  function onTitleChange(event)
  {
    event.preventDefault();
    setName(event.target.value)
  }

  function onListStatusCheckboxChanged(event)
  {
    setListIsActive(!listIsActive)
  }

  function onAssetTypeCheckboxChanged(event)
  {
    setAssetTypeIsActive(!assetTypeIsActive)
  }

  function onRadiobuttonChanged(items, setItems, setValue, codename)
  {
    let _items = [...items]
    for (let i = 0; i < _items.length; i++)
    {
      if (_items[i].codename === codename)
      {
        _items[i].checked = true
        setValue(codename)
      }
      else  
      {
        _items[i].checked = false
      }
    }
    setItems(_items)
  }

  function onRadiobuttonListTypeChanged(items, setItems, setValue, codename) // seperate function as it has custome code
  {
    let _items = [...items]
    for (let i = 0; i < _items.length; i++)
    {
      if (_items[i].codename === codename)
      {
        _items[i].checked = true
        setValue(codename)
      }
      else  
      {
        _items[i].checked = false
      }
    }
    setItems(_items)

    if (codename === "static") // custom code
    {
      // disable everything and set switches to disabled
      setAssetTypeIsActive(false)
    }
  }

  function onCheckboxChanged(items, setItems, codename)
  {
    let _items = [...items]
    for (let i = 0; i < _items.length; i++)
      {
        if (_items[i].codename === codename)
        {
          _items[i].checked = !_items[i].checked
          break
        }
      }
    setItems(_items)
  }

  // ASSET //

  function onAssetSearchChange(event)
  {
    event.preventDefault();
    setAssetSearchPhrase(event.target.value)
    if (event.target.value.length > 2)
    {
      findAsset(event.target.value)
    }
    else  
    {
      setAssetsList([])
    }
  }

  function handleAddAsset(item)
  {
    for (let i = 0; i < assetsSelected.length; i++)
    {
      if (assetsSelected[i].objectId === item.objectId)
      {
        alert(assetsSelected[i].title + " is already added.")
        return
      }
    }
    let _items = [...assetsSelected]
    _items.push(item)
    setAssetsSelected(_items)
    setAssetSearchList([])
    setAssetSearchPhrase("")
  }

  async function findAsset(phrase)
  {
    const Asset = Parse.Object.extend("Asset");
    const query = new Parse.Query(Asset);
    query.limit(5)
    query.contains("searchText", phrase);
    const result = await query.find()
    let items = []
    for (let i = 0; i < result.length; i++)
    {
      items.push({
        title: result[i].get("title"),
        year: result[i].get("year"),
        objectId: result[i].id,
      })  
    }
    setAssetSearchList(items)
  }

  async function getAssetsData(assetsObjectIds)
  {
    const Asset = Parse.Object.extend("Asset");
    const query = new Parse.Query(Asset);
    query.containedIn("objectId", assetsObjectIds)
    query.select("title","year")
    const result = await query.find()
    let items = []
    for (let i = 0; i < result.length; i++)
    {
      items.push({
        title: result[i].get("title"),
        year: result[i].get("year"),
        objectId: result[i].id,
      })  
    }
    console.log(items)
    setAssetsSelected(items)
  }

  function handleDeleteAsset(itemObjectId)
  {
    let _items = [...assetsSelected]
    for (let i = 0; i < _items.length; i++)
    {
      if (_items[i].objectId === itemObjectId)
      {
        _items.splice(i, 1)
        break
      }
    }
    setAssetsSelected(_items)
  }
  // END ASSET //

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => 
  {
    if (list_id === "add_new")
    {
      setEditUiFunction()
      setDefaultValues()
    }
    else  
    {
      getList(list_id)
    }
  }, [])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <NavBar title={"List: " + name} showBackButton={true} />
        <Box
          component="nav"
          sx={{ width: { sm: sideMenuWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <SideMenu/>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${sideMenuWidth}px)` } }}
        >
          <Toolbar/>
          <Grid>
            <Tabs value={activeTab} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab label="overview" />
              <Tab label="edit metadata" />
              <Tab label="edit images" />
            </Tabs>
            <TabPanel value={activeTab} index={0}> {/* VIEW */} 
              <Grid>
                <h1>List name: {name}</h1>
              </Grid>
              <Grid>
                <p>List type: {whatTypeValue}</p>
              </Grid>
              <Grid>
                <p>Asset type: {assetTypeValue} status: {assetTypeIsActive?"active":"not active"}</p>
              </Grid>
              <Grid>
                <p>Tile type: {tileTypeValue}</p>
              </Grid>

              {imageBannerSmallUrl?<Grid>
                <Grid>
                  Banner
                </Grid>
                <Grid>
                   <img
                    src={imageBannerSmallUrl}
                    width="480px"
                    height="270px"
                    alt="poster"
                    style={{width:"480px", height:"270px"}}
                  />
                </Grid>
              </Grid>:null}
            </TabPanel>

            <TabPanel value={activeTab} index={1}>{/* EDIT */}
              <Grid> 
                <Grid container spacing={{ xs: 2}} columns={{ xs: 6 }}>
                  <Grid item xs={1}>
                    <Button variant="contained" onClick={onSaveButtonClicked}>Save metadata</Button>
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 2}} columns={{ xs: 6 }}>
                  <Grid item xs={2} style={{marginTop:"32px", marginBottom:"32px"}}>
                    <TextField  id="outlined-basic" label="name of the list" variant="outlined" value={name} onChange={onTitleChange} error={showInputError} />
                  </Grid>
                </Grid>

                <Grid container spacing={{ xs: 2}} columns={{ xs: 6 }}>
                  <Grid item xs={1}>
                    List status
                  </Grid>
                  <Grid item xs={4}>
                    is list active and visible for end users
                  </Grid>
                  <Grid item xs={1}>
                    <FormControlLabel control={
                      <Switch  
                        id="custom-switch"
                        label="wyłączone"
                        checked={listIsActive}
                        onChange={onListStatusCheckboxChanged} 
                      />} 
                      label={listIsActive?"Active":"Not active"} />
                  </Grid>
                </Grid>

                <Grid container spacing={{ xs: 2 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                  <Grid item xs={1}>
                    Available on Page:
                    <FormHelperText error={showPagesError}>Pick at least 1</FormHelperText>
                  </Grid>
                  <Grid item xs={5}>
                    {pages.map((item) => ( 
                        <FormControlLabel key={item.codename} control={<Checkbox checked={item.checked} onChange={(e)=>{onCheckboxChanged(pages, setPages, item.codename)} } />} label={item.name} />                           
                    ) )}
                  </Grid>
                </Grid>

                <Grid container spacing={{ xs: 2 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                  <Grid item xs={1}>
                    List type:
                  </Grid>
                  <Grid item xs={5}>
                     {whatTypeList.map((item) => ( 
                        <FormControlLabel key={item.codename} value={item.codename} control={<Radio checked={item.checked} onChange={()=>{onRadiobuttonListTypeChanged(whatTypeList, setWhatTypeList, setWhatTypeValue, item.codename)}}/>} label={item.name} />
                    ) )}
                  </Grid>
                </Grid>

                <Grid container spacing={{ xs: 2 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                  <Grid item xs={1}>
                    Tile type:
                  </Grid>
                  <Grid item xs={5}>
                    {tileTypeList.map((item) => ( 
                      <FormControlLabel key={item.codename} value={item.codename} control={<Radio checked={item.checked} onChange={()=>{onRadiobuttonChanged(tileTypeList, setTileTypeList, setTileTypeValue, item.codename)}} />} label={item.name} />
                    ) )}
                  </Grid>
                </Grid>

                {whatTypeValue==="static"?<>
                    
                  <Grid container spacing={{ xs: 2 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                    <Grid item xs={2}>
                      <h2>Assets</h2>
                      <div>Static list presents only selected assets.</div>
                      <TextField disabled={assetsSelected.length>20?true:false} id="outlined-basic" label="type in asset title" variant="outlined" value={assetSearchPhrase} onChange={onAssetSearchChange} style={{marginTop:"16px"}}/>
                      <FormControl >
                        <InputLabel shrink htmlFor="select-multiple-native">
                          select
                        </InputLabel>
                        {assetSearchList.length>0?<Select
                          multiple
                          native
                          label="Native"
                          inputProps={{
                            id: 'sub-media-file',
                          }}
                        >
                          {assetSearchList.map((item) => (
                            <option key={item.objectId} value={item.name} onClick={()=>{handleAddAsset(item);}}>
                              {item.title + " | " + item.year}
                            </option>
                          ))}
                        </Select>:null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack direction="row" spacing={1} style={{marginTop:"16px"}}>
                        {assetsSelected.map((item) => ( 
                            <Chip label={item.title + " | " + item.year} onDelete={()=>{handleDeleteAsset(item.objectId)} } key={item.objectId}/>                           
                        ) )}
                      </Stack>
                    </Grid>
                  </Grid>
                </>:
                <>
                  <Grid container spacing={{ xs: 2 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                    <Grid item xs={1}>
                      Asset type:
                    </Grid>
                    <Grid item xs={4}>
                      {assetTypeList.map((item) => ( 
                        <FormControlLabel key={item.codename} value={item.codename} control={<Radio checked={item.checked} onChange={()=>{onRadiobuttonChanged(assetTypeList, setAssetTypeList, setAssetTypeValue, item.codename)}} />} label={item.name} />
                      ) )}
                    </Grid>
                    <Grid item xs={1}>
                      <FormControlLabel control={
                        <Switch  
                          id="custom-switch"
                          label="wyłączone"
                          checked={assetTypeIsActive}
                          onChange={onAssetTypeCheckboxChanged} 
                        />} 
                        label={assetTypeIsActive?"Active":"Not active"} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={{ xs: 2 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                    <Grid item xs={1}>
                      Categories
                    </Grid>
                    <Grid item xs={5}>
                      {categoriesList.map((item) => ( 
                          <FormControlLabel control={<Checkbox checked={item.checked} onChange={(e)=>{onCheckboxChanged(categoriesList, setCategoriesList, item.codename)} } />} label={item.name} />                           
                      ) )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={{ xs: 2 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                    <Grid item xs={1}>
                      Tags
                    </Grid>
                    <Grid item xs={5}>
                      {tagsList.map((item) => ( 
                          <FormControlLabel control={<Checkbox checked={item.checked} onChange={(e)=>{onCheckboxChanged(tagsList, setTagsList, item.codename)} } />} label={item.name} />                           
                      ) )}
                    </Grid>
                  </Grid>
                </>}
              </Grid>
                  
            </TabPanel>

            <TabPanel value={activeTab} index={2}>
              <Grid> 
                <Grid style={{marginBottom: "16px"}} >
                  <Grid item>
                    <Button variant="contained" onClick={updateItemImages}>save images</Button>
                  </Grid>
                </Grid>

                <Grid container >
                  <Grid item xs={4}>
                    <Button variant="outlined" component="label">
                      set small banner {defaultImagesSizeBannerSmall.width}x{defaultImagesSizeBannerSmall.height}px jpg
                      <input hidden accept=".jpg, .jpeg" type="file" onChange={imageBannerSmallHandleChange} />
                    </Button>
       
                    {imageBannerSmallUrl?<Grid item>
                       <img
                        src={imageBannerSmallUrl}
                        width="240px"
                        height="135px"
                        alt="poster"
                        style={{width:"240px", height:"135px"}}
                      />
                    </Grid>:null}
                  </Grid>

                  <Grid item xs={4}>
                    <Button variant="outlined" component="label">
                      set medium banner {defaultImagesSizeBannerMedium.width}x{defaultImagesSizeBannerMedium.height}px jpg
                      <input hidden accept=".jpg, .jpeg" type="file" onChange={imageBannerMediumHandleChange} />
                    </Button>

                    {imageBannerMediumUrl?<Grid item>
                       <img
                        src={imageBannerMediumUrl}
                        width="280px"
                        height="158px"
                        alt="poster"
                        style={{width:"280px", height:"158px"}}
                      />
                    </Grid>:null}
                  </Grid>

                  <Grid item xs={4}>
                    <Button variant="outlined" component="label">
                      set large banner {defaultImagesSizeBannerLarge.width}x{defaultImagesSizeBannerLarge.height}px jpg
                      <input hidden accept=".jpg, .jpeg" type="file" onChange={imageBannerLargeHandleChange} />
                    </Button>

                    {imageBannerLargeUrl?<Grid item>
                       <img
                        src={imageBannerLargeUrl}
                        width="320px"
                        height="180px"
                        alt="poster"
                        style={{width:"320px", height:"180px"}}
                      />
                    </Grid>:null}
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>   
          </Grid>
        </Box>
      </Box>
    </>
  );
}