import Parse from 'parse';
import { useState, useRef, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu, sideMenuWidth } from "../components/sidemenu"
import { getTimestampAsStringForDatePicker } from "../services/helper"

import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import { getConfigValue } from "../custom/config"

export default function Product() 
{
  const { product_id } = useParams();

  const navigate = useNavigate()  

  const [editUi, setEditUi] = useState(false);


  const [isActive, setIsActive] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [objectId, setObjectId] = useState("");

  const [whatTypeValue, setWhatTypeValue] = useState("");
  const [whatTypeList, setWhatTypeList] = useState([]);

  const [currency, setCurrency] = useState("pln");
  const [priceValue, setPriceValue] = useState(0);
  const [priceDisplayName, setPriceDisplayName] = useState(0);


  const [duration, setDuration] = useState(0);
  const [expirationDate, setExpirationDate] = useState(0);
  const [expirationDateString, setExpirationDateString] = useState("");
  const [whatExpirationType, setWhatExpirationType] = useState("duration");
  const [expirationTypeList, setExpirationTypeList] = useState([
        {name: "Duration from purchase time", codename: "duration", checked: true},
        {name: "Expiration on selected date", codename: "expiration", checked: false}
  ])

  const [assetLimit, setAssetLimit] = useState(0);

  const [deviceLimit, setDeviceLimit] = useState(getConfigValue("setDeviceLimit"));



  async function getItem(productObjectId)
  {
    const Item = Parse.Object.extend("Product");
    const query = new Parse.Query(Item);
    const result = await query.get(productObjectId)

    setName(result.get("name"))
    setDescription(result.get("description"))

    setWhatTypeValue(result.get("whatType"))
    setObjectId(result.id)
    setIsActive(result.get("isActive"))
    setCurrency(result.get("currency"))
    setPriceValue(result.get("price"))
    setPriceDisplayName(result.get("price")/100)
    setDeviceLimit(result.get("deviceLimit"))

    setDuration( result.get("duration") )
    setExpirationDate( result.get("expirationDate") )

    if (result.get("duration") > 0)
    {
      onRadioButtonChanged(expirationTypeList, setExpirationTypeList, setWhatExpirationType, "duration")
    }
    else
    {
      onRadioButtonChanged(expirationTypeList, setExpirationTypeList, setWhatExpirationType, "expiration")
      setExpirationDateString(getTimestampAsStringForDatePicker(result.get("expirationDate")))
    }

    const productTypeListConfig = getConfigValue("productTypes")
    let productTypeListDefault = []
    for (let i = 0; i < productTypeListConfig.length; i++)
    {
      productTypeListDefault.push({
        name: productTypeListConfig[i].name,
        codename: productTypeListConfig[i].codename,
        checked: false
      })
    }
    onRadioButtonChanged(productTypeListDefault, setWhatTypeList, setWhatTypeValue, result.get("whatType")) // update all walues for settings and for UI
  }
  
  async function addNewItem()
  {
    if (name !== "")
    {
      if (priceValue === "") { priceValue = 0 }
      if (duration === "") { duration = 0 }

      const Add = Parse.Object.extend("Product");
      let add = new Add;
      let acl = new Parse.ACL()
      acl.setWriteAccess("*", false)
      acl.setReadAccess("*", true)
      acl.setRoleReadAccess("admin", true)
      acl.setRoleWriteAccess("admin", true)
      add.setACL(acl);

      add.set("name", name)
      add.set("description", description)  
      add.set("isActive", isActive)
      add.set("currency", currency)
      add.set("whatType", whatTypeValue)
      add.set("price", Number(priceValue) )
      add.set("deviceLimit", Number(deviceLimit) )

      if (whatExpirationType === "duration")
      {
        add.set("duration", Number(duration) )
        add.set("expirationDate", 0 )
      }
      else if (whatExpirationType === "expiration")
      {
        add.set("duration", 0 )
        add.set("expirationDate", expirationDate )
      }

      const result = await add.save();
      navigate(-1)
    }
    else  
    {
      alert("Name is required")
    }
  }

  async function updateItemData()
  {
    if (priceValue === "") { priceValue = 0 }
    if (duration === "") { duration = 0 }
    
    const Item = Parse.Object.extend("Product");
    const query = new Parse.Query(Item);
    const result = await query.get(objectId)

    result.set("name", name)
    result.set("description", description)

    result.set("isActive", isActive)
    result.set("currency", currency)
    result.set("whatType", whatTypeValue)
    result.set("price", Number(priceValue) )
    if (whatExpirationType === "duration")
    {
      result.set("duration", Number(duration) )
      result.set("expirationDate", 0 )
    }
    else if (whatExpirationType === "expiration")
    {
      result.set("duration", 0 )
      result.set("expirationDate", expirationDate )
      setExpirationDateString(getTimestampAsStringForDatePicker(expirationDate))
    }
    result.set("deviceLimit", Number(deviceLimit) )

    await result.save()
    setViewUiFunction()
  }

  function onIsActiveCheckboxChanged(event)
  {
    setIsActive(!isActive)
  }

  function setEditUiFunction()
  {
    setEditUi(true)
  }

  function setViewUiFunction()
  {
    setEditUi(false)
  }

  // on input change callback
  function onTitleChange(event)
  {
    event.preventDefault();
    setName(event.target.value)
  }

  function onDescriptionChange(event)
  {
    event.preventDefault();
    setDescription(event.target.value)
  }

  function onDurationChange(event)
  {
    event.preventDefault();
    setDuration(event.target.value)
  }

  function onDeviceLimitChange(event)
  {
    event.preventDefault();
    setDeviceLimit(event.target.value)
  }

  function onTimeAndDateChanged(set, value)
  {
    const date = new Date(value)
    set(Math.round(date.getTime()/1000))
  }

  function onPriceChange(event)
  {
    event.preventDefault();
    console.log(event.target.value)
    setPriceValue(event.target.value)
    if (event.target.value != "")
    {
      setPriceDisplayName(event.target.value/100)
    }
  }

  function onRadioButtonChanged(items, setItems, setValue, codename)
  {
    let _items = [...items]
    for (let i = 0; i < _items.length; i++)
    {
      if (_items[i].codename === codename)
      {
        _items[i].checked = true
        setValue(codename)
      }
      else  
      {
        _items[i].checked = false
      }
    }
    setItems(_items)

    // custom update 
    if (codename === "svod")
    {
      setAssetLimit(10000)
    }
    else if (codename === "tvod")
    {
      setAssetLimit(1)
    }
  }

  async function onSaveButtonClicked()
  {
    if (product_id === "add_new")
    {
      addNewItem()
    }
    else  
    {
      updateItemData()
    }
  }

  function onCancelButtonClicked()
  {
    if (product_id === "add_new")
    {
      navigate(-1)
    }
    else  
    {
      setViewUiFunction()
    }
  }

  useEffect(() => 
  {
    if (product_id === "add_new")
    {
      setEditUiFunction()
      const productTypeListConfig = getConfigValue("productTypes")
      let productTypeListDefault = []
      for (let i = 0; i < productTypeListConfig.length; i++)
      {
        productTypeListDefault.push({
          name: productTypeListConfig[i].name,
          codename: productTypeListConfig[i].codename,
          checked: false
        })
      }
      onRadioButtonChanged(productTypeListDefault, setWhatTypeList, setWhatTypeValue, productTypeListDefault[0].codename) // update all walues for settings and for UI
    }
    else  
    {
      getItem(product_id)
    }
  }, [])

  return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <NavBar title = {name} showBackButton={true} />
        <Box
          component="nav"
          sx={{ width: { sm: sideMenuWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <SideMenu/>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${sideMenuWidth}px)` } }}
        >
          <Toolbar/>
          <Grid>            
   
           {editUi?<> {/* EDIT */}
              <Grid> 
               <Grid container spacing={{ xs: 2}} columns={{ xs: 6 }}>
                <Grid item xs={1}>
                  <Button variant="contained" onClick={onSaveButtonClicked}>save</Button>
                </Grid>
                <Grid item xs={1}>
                  <Button onClick={onCancelButtonClicked}>cancel</Button>
                </Grid>
              </Grid>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                  <Grid item xs={1}>
                    Product status
                  </Grid>
                  <Grid item xs={5}>
                    <FormControlLabel control={
                      <Switch  
                        id="custom-switch"
                        label="wyłączone"
                        checked={isActive}
                        onChange={onIsActiveCheckboxChanged} 
                      />} 
                      label={isActive?"Active":"Not active"} />
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                  <Grid item xs={1}>
                    <TextField  id="outlined-basic" label="name of the product" variant="outlined" value={name} onChange={onTitleChange} />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField style={{width:"100%"}} id="outlined-basic" label="description of the product" variant="outlined" value={description} onChange={onDescriptionChange} />
                  </Grid>
                  <Grid item xs={2}>

                  </Grid>
                </Grid>

                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                  <Grid item xs={1}>
                    What type:
                  </Grid>
                  <Grid item xs={5}>
                     {whatTypeList.map((item) => ( 
                      <div>
                        <label key={item.codename}><input type="radio" name="list_type" checked={item.checked} onChange={()=>{onRadioButtonChanged(whatTypeList, setWhatTypeList, setWhatTypeValue, item.codename)}}/> {item.name}</label>
                      </div>
                    ) )}
                  </Grid>
                </Grid>

                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                  <Grid item xs={6}>
                    <TextField type="number" id="outlined-basic" label="price in gr" variant="outlined" value={priceValue} onChange={onPriceChange} />
                  </Grid>
                </Grid>

                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                  <Grid item xs={1}>
                    Expiration type:
                  </Grid>
                  <Grid item xs={5}>
                     {expirationTypeList.map((item) => ( 
                      <div>
                        <label key={item.codename}><input type="radio" name="expiration" checked={item.checked} onChange={()=>{onRadioButtonChanged(expirationTypeList, setExpirationTypeList, setWhatExpirationType, item.codename)}}/> {item.name}</label>
                      </div>
                    ) )}
                  </Grid>
                </Grid>
                {whatExpirationType==="duration"? 
                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                    <Grid item xs={6}>
                      <TextField type="number" id="outlined-basic" label="duration in min." variant="outlined" value={duration} onChange={onDurationChange} />
                    </Grid>
                  </Grid>
                :null}

                {whatExpirationType==="expiration"? 
                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                    <Grid item xs={6}>
                      <TextField
                          id="datetime-local"
                          label="expiration on date"
                          type="datetime-local"
                          defaultValue={expirationDateString}
                          sx={{ width: 250 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(event)=>{ onTimeAndDateChanged(setExpirationDate, event.target.value) }}
                        />                 
                    </Grid>
                  </Grid>
                :null}

                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                  <Grid item xs={6}>
                    Device limit (on how many deviced end user can watch within 24h period)
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6 }} style={{marginTop:"16px"}}>
                  <Grid item xs={6}>
                    <TextField type="number" id="outlined-basic" label="device limit" variant="outlined" value={deviceLimit} onChange={onDeviceLimitChange} />
                  </Grid>
                </Grid>

              </Grid>
            </>:<> {/* VIEW */}
              <Grid>
                <Button variant="text" onClick={setEditUiFunction}>Edit</Button>
              </Grid>
              <Grid>
                <h2> {name}</h2>
              </Grid>

              <Grid>
                <h4> {description}</h4>
              </Grid>

              <Grid>
                <p>What type: {whatTypeValue}</p>
              </Grid>

              <Grid>
                <p>Price: {priceDisplayName}</p>
              </Grid>

              <Grid>
                <p>Currency: {currency}</p>
              </Grid>

              <Grid>
                {whatExpirationType==="duration"?<p>Duration: {duration} min | (around: {Math.round(duration/60)} h) </p>:null}
                {whatExpirationType==="expiration"?<p>Expiration date: {expirationDateString} </p>:null}

              </Grid>
              <Grid>
                <p>Device limit: {deviceLimit}</p>
              </Grid>

              <Grid>
                <p>Status: {isActive?"active":"not active"}</p>
              </Grid>

            </>}      
          </Grid>
        </Box>
      </Box>
  );
}