import Parse from "parse";
import { getPurchasedProducts } from "../services/api"


export const loginUser = async( username, password, callback ) => 
{
  if (username !== null && username !== "" && password !== null && password !== "")
  {
      try 
      {
        const user_data = await Parse.User.logIn(username, password);
        if (user_data && user_data.get("whatType") === "admin") // get user  via api.
        {
          if (callback) 
          { 
            callback() 
          }
          return "ok"
        }
        else
        {
          console.log("no_credentials")
          return "no_credentials"
        } 
      } 
      catch (error) 
      {
        console.log(error)
        return "error"
      }
  }
  else
  {
    let user_data = await Parse.User.current();
    // console.log(user_data)
    if (user_data && user_data.get("whatType") === "admin") // get user  via api.
    {
      // updateLocalStorage(user_data)
      if (callback) 
      { 
        callback() 
        console.log("callback") 
      }
      console.log("ok")
      return "ok"
    }
    else
    {
      console.log("no_credentials")
      return "no_credentials"
    }
  }
};

export const logoutUser = async(callback) => {
  try 
  {
    await Parse.User.logOut()
    if (callback)
    {
      callback()
    }
    return true
  } 
  catch (error) 
  {
    return false
  }
};

export function isUserLoggedIn()
{
    let user_data = Parse.User.current()
    if (user_data == null)
    {
      return false
    }
    else 
    {
      // updateLocalStorage(user_data)
      return true
    }
};

export function alloweOnlyLoggedInUsers()
{
    let user_data = Parse.User.current()
    if (user_data == null)
    {
      if (window.location.pathname !== "/" && window.location.pathname !== "/authenticate")
      {
        window.location.href = "/"
      }
    }
};

export function getUserObjectId()
{
    const user_data = Parse.User.current()
    if (user_data == null)
    {
      return null
    }
    else 
    {
      return user_data.id
    }
};

export function getUserLogin()
{
    const user_data = Parse.User.current()
    if (user_data == null)
    {
      return null
    }
    else 
    {
      return user_data.attributes.username
    }
};

export const refreshUserData = async() => {
  if (Parse.User.current())
  {
    Parse.User.current().fetch()
  }
};

export function getUserData()
{
  return Parse.User.current()
}

