
export const timestampDateToString = (timestamp) => 
{
  const today = new Date()
  let a = new Date(timestamp*1000);
  let time = ""
  if (today.getDate() !== a.getDate())
  {
    time = time + a.getDate() + " / " + (a.getMonth()+1) + " / " + a.getFullYear() + " | "
  }

  var minutes = "0" + a.getMinutes()
  time = time + "godz. " + a.getHours() + ":" + minutes.substr(-2);

  return time;
};

export const isSafari = () => 
{
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export const getTimestampAsStringForDatePicker = (time) => 
{
  const date = new Date(time*1000)
  let str = date.getFullYear().toString()
  let month = date.getMonth()+1
  if (month < 10) { str = str + "-0" + month.toString() } else { str = str + "-" + month.toString() }
  let day = date.getDate()
  if (day < 10) { str = str + "-0" + day.toString() + "T" } else { str = str + "-" + day.toString() + "T" }
  let hour = date.getHours()
  if (hour < 10) { str = str + "0" + hour.toString() } else { str = str + hour.toString() }
  let min = date.getMinutes()
  if (min < 10) { str = str + ":0" + min.toString() } else { str = str + ":" + min.toString() }
  return str
}
