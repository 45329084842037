import Parse from "parse";
import { setCacheDuration } from "../services/api"

export const getConfig = async() => 
{
  const _config_timestamp = Number(window.localStorage.getItem("configTimestamp"))
  const current_time = Date.now();
  if (_config_timestamp && (_config_timestamp + 60*60) > current_time/1000)
  {
    const config = await Parse.Config.current()
    if (config)
    {
      return config
    }
  }
  window.localStorage.setItem("configTimestamp", current_time/1000) // lat that app was fully new loaded
  const config = await Parse.Config.get()
  setCacheDuration(config.get("cacheDuration"))
  return config
  
};


export const getMenuConfig = async() => 
{
  const config = await getConfig()
  return config.get("mainMenu")
};
