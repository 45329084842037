import Parse from 'parse';
import { useState, useEffect} from "react";
import { useNavigate  } from "react-router-dom"
import { NavBar } from "../components/navbar"
import { SideMenu, sideMenuWidth } from "../components/sidemenu"

import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    editable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    editable: false,
  }
];


export default function ChannelsList() 
{
  const navigate = useNavigate()  
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [searchSafeTimer, setSearchSafeTimer] = useState()

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    navigate("/tvchannels/"+params.row.objectId)
  };

  async function getAssets(search_phrase)
  {
    setIsLoading(true)
    const Channel = Parse.Object.extend("Channel");
    const query = new Parse.Query(Channel);
    query.descending("sortIndex")
    query.limit(100)  
    const result = await query.find()

    let items = []

    for (let i = 0; i < result.length; i++)
    {
      items.push({
        objectId: result[i].id,
        id: result[i].id,
        name: result[i].get("name"),
        status: result[i].get("status")
      })
    }
    setIsLoading(false)
    setItems(items)
  }

  useEffect(() => 
  {
    getAssets()
  }, [])

  return (
    <>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar title={"Channels"} />
      <Box
        component="nav"
        sx={{ width: { sm: sideMenuWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <SideMenu/>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${sideMenuWidth}px)` } }}
      >
        <Toolbar/>
        <Grid>
          <Box sx={{ height: "720px" }}>
            <DataGrid
              rows={items}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              onRowClick={handleRowClick}
              loading={isLoading}
            />
          </Box>
        </Grid>
      </Box>
    </Box>
    </>
  );
}